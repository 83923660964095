import React, { useState } from 'react';
import './CustomButton.css';
import FilledButton from './FilledButton';
import { useSendSMSMutation } from '../../features/sms/smsSlice';
import { useSnackbar } from '../SneckBarProvider';
import { useTranslation } from 'react-i18next';
import { CircleNotifications } from '@mui/icons-material';
import ComfirmationDialog from '../Dialog/ConfirmationDialog';
import { useSignalRConection } from '../../store/context/SignalRContext';

const SMSButton = (props) => {
  const { isButtonEnabled } = useSignalRConection();

  console.log(isButtonEnabled)

  const { reservations } = props;
  const [openComfDialog, setOpenComfDialog] = useState();
  const { t } = useTranslation();
  const [sendSMS] = useSendSMSMutation();
  const showSnacbar = useSnackbar();

  console.log(reservations);
  const handleSendSMSClick = async () => {
    if (reservations?.length > 0 && isButtonEnabled) {
      setOpenComfDialog(false)
      await handleSendSMS();
    }
  };
  const handleSendSMS = async () => {
    try {
      console.log(reservations);
      const response = await sendSMS(reservations);
      console.log(response);
      if (!response.error) {
      } else {
        showSnacbar(response?.error.data?.Message, 'error');
      }

    } catch (error) {
      console.error('Error:', error);
      showSnacbar(error?.data?.Message, 'error');
    }
  };
  const handleOpenComfDialog = () => {
    setOpenComfDialog(true);
  };
  const sx = {
    minWidth: '160px',
  };


  return (
    <>
      <FilledButton
        style={sx}
        isValid={reservations.length > 0 && isButtonEnabled}
        onClick={handleOpenComfDialog}
      >
        {
          isButtonEnabled ?
            <>
              <CircleNotifications />
              {t('reservations.notify')}
            </>
            :
            t('reservations.sendingMessages')
        }

      </FilledButton>

      {openComfDialog && (
        <ComfirmationDialog
          open={openComfDialog}
          onClose={() => setOpenComfDialog(false)}
          dialogContent={t('sendSMSs')}
          onNo={() => setOpenComfDialog(false)}
          onYes={handleSendSMSClick}
        />
      )}
    </>
  );
};

export default SMSButton;
