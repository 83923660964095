import * as React from 'react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';
import { useLazyGetVehiclesQuery } from '../../../features/vehicle/VehicleSlice';
import { useAddVehicleToBookingMutation } from '../../../features/vehicle/VehicleSlice';
import SearchableSelect from '../../Search/SearchableSelect';

export default function AddVehicleForm({ onSuccess, reservations, rideProperties }) {
  const { t } = useTranslation();
  const [getVehicles] = useLazyGetVehiclesQuery();

  const [addVehicleToBooking, { isLoading, isSuccess, isError, error }] =
    useAddVehicleToBookingMutation();

  const [vehicles, setVehicles] = useState([]);

  const [selectedItem, setSelectedItem] = useState(null);

  const fetchVehicles = async () => {
    const response = await getVehicles();
    if (response.data) {
      setVehicles(response.data);
    }
  };

  useEffect(() => {
    fetchVehicles();
  }, [getVehicles]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const addVehicleToBookingObj = { vehicle: selectedItem, reservations, rideProperties };
      await addVehicleToBooking(addVehicleToBookingObj).unwrap();
      if (onSuccess) {
        onSuccess();
      }
    } catch (err) {
      console.error('Failed to add vehicle to booking: ', err);
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <SearchableSelect
        setSelected={setSelectedItem}
        items={vehicles}
        itemName={'Vehicle'}
      ></SearchableSelect>
      <Button
        sx={{ marginTop: '10px' }}
        type="submit"
        className="create-button"
        color="primary"
        disabled={selectedItem === null}
      >
        {isLoading ? t('customDialog.adding') : t('customDialog.addVehicle')}
      </Button>
      {isError && (
        <p>
          {t('customDialog.errorAdding')}: {error.toString()}
        </p>
      )}
    </Box>
  );
}
