import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import AllBookingsPage from './pages/AllBookingsPage';
import AddBookingPage from './pages/AddBookingPage';
import RoutesPage from './pages/RoutesPage';
import RoutePage from './pages/RoutePage';
import BookingRequestsPage from './pages/BookingRequestsPage';
// import RequireAuth from "../src/features/auth/RequireAuth";
import RequireAuth from './features/auth/ReguireAuth.js';
import Layout from '../src/components/Layout';
import Unauthorized from './features/auth/Unauthorized.js';
import UserRequestsPage from './pages/UserRequestsPage.js';
import ShowAllUsersComponent from './components/User/UsersTable/ShowAllUsersComponent.js';
import UserProfile from './components/User/UserProfile/UserProfile.jsx';
import { SnackbarProvider } from './components/SneckBarProvider.jsx';
import AppBarComponent from './components/Navbar/Appbar.js';
import AddRouteByAutoCompleteForm from './components/Route/AddRouteComponent/AddRouteByAutoCompleteForm.jsx';
import GooglePlacesAutocomplete from './components/GoogleMap/GooglePlacesAutocomplete .jsx';
import TextAreaWithAutocomplete from './components/GoogleMap/TextAreaWithAutoComplete.jsx';
import AllReservationsPage from './pages/AllReservationsPage.js';
import CreateBookingComponent from './components/Booking/AddBooking/CreateBookingComponent.js';
import { ValidationProvider } from './components/Contexts/ValidationProvider.js';
import RegisterInvitationPage from './pages/RegisterInvitationPage.js';
import PassengerRegistrationConfirmPage from './pages/PassengerRegistrationConfirmPage.js';
import Eror404 from './pages/Eroro404.js';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import SubagentPage from './pages/SubagentsPage.js';
import PaymentMethodPage from './pages/PaymentMethod.js';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import EditBookingComponent from './components/Booking/EditBooking/EditBookingComponent.js';
import { AddCustomLocation } from './components/CustomLocations/AddCustomLocation.jsx';
import AddCustomLocationPage from './pages/AddCustomLocationPage.js';
import InputAutoComplete from './components/GoogleMap/InputAutocomplete.jsx';
import { CustomLocations } from './components/CustomLocations/CustomLocations.jsx';
import PassangersDetails from './components/PassangersDetails/PassangersDetails.js';
import PassengersLocation from './components/Route/PassengersLocationMap/PassengersLocation.js';
import BookingDetails from './components/Booking/BookingDetails/BookingDetails.js';
import UnapprovedReservations from './components/Reservations/AddReservation/UnapprovedReservation/UnapprovedReservation.js';
import ThankYouPage from './pages/ThankYouPage.js';
import { SignalRProvider } from './store/context/SignalRContext.js';
import SMSStatusPage from './pages/SMSStautsPage.js';
import { RecordsProvider } from './store/context/SMSRecordContext.js';
import RidesPage from './pages/RidesPage.js';

function App() {
  const { t, i18n } = useTranslation();
  return (
    <RecordsProvider>

    <SignalRProvider>
        <SnackbarProvider>
          <Suspense fallback="...loading">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <ValidationProvider>
                <Router>
                  <Routes>
                    <Route path="/" element={<Layout />}>
                      {/* Public routes */}

                      <Route index element={<LoginPage />} />
                      <Route
                        path="/InputAuto"
                        element={<InputAutoComplete />}
                      />

                      <Route path="/unauthorized" element={<Unauthorized />} />
                      <Route
                        path="/RegisterInvitation"
                        element={<RegisterInvitationPage />}
                      />
                      <Route
                        path='/ConfirmRegistration'
                        element = {<PassengerRegistrationConfirmPage />}
                      />

                      <Route
                        path="/ReservationConfirmation/:paramName"
                        element={<ThankYouPage />}
                      />
                      <Route path="/SMSStatus" element={<SMSStatusPage />} />

                      <Route
                        path="/PassengersLocation"
                        element={<PassengersLocation></PassengersLocation>}
                      ></Route>

                      {/* Protected routes */}

                      <Route
                        element={
                          <RequireAuth
                            allowedRoles={['Admin', 'Operater', 'Subagent']}
                          />
                        }
                      >
                        <Route
                          path="/CreateBooking"
                          element={<CreateBookingComponent />}
                        />
                        <Route path="/Bookings" element={<AllBookingsPage />} />

                        <Route
                          path="/Reservations"
                          element={<AllReservationsPage />}
                        />
                      </Route>

                      {/* double route and below Protected routes*/}
                      {/* <Route
                    path="/Reservations"
                    element={<AllReservationsPage />}
                  /> */}
                      <Route
                        element={
                          <RequireAuth
                            allowedRoles={['Admin', 'Operater', 'Subagent']}
                          />
                        }
                      >
                        <Route
                          path="/BookingDetails/:reservationId"
                          element={<PassangersDetails />}
                        />
                        <Route
                          path="/ReservationDetails/:reservationId/:routeId"
                          element={<BookingDetails />}
                        />
                        <Route
                          path="/UnapprovedReservations"
                          element={<UnapprovedReservations />}
                        />

                        <Route
                          path="/AddBooking"
                          element={<AddBookingPage />}
                        />

                        <Route path="/RoutesPage" element={<RoutesPage />} />
                        {/* :bookingId */}
                        <Route
                          path="/RoutePage/:routeId/:reservationId/:bookingId"
                          element={<RoutePage />}
                        />
                        <Route
                          path="/UserProfile/:userId"
                          element={<UserProfile />}
                        />

                        <Route
                          path="/BookingRequests"
                          element={<BookingRequestsPage />}
                        />
                        <Route
                          path="/Users"
                          element={<ShowAllUsersComponent />}
                        />

                        <Route
                          path="/PaymentMethods"
                          element={<PaymentMethodPage />}
                        />
                        {/* <Route
                      path="/:lang/EditBooking/:reservationId/:passengerId"
                      element={<EditBookingComponent />}
                    ></Route> */}
                        <Route
                          path="/:lang/EditBooking/:reservationId/"
                          element={<EditBookingComponent />}
                        ></Route>
                        <Route
                          path="/AddCustomLocation"
                          element={<AddCustomLocationPage />}
                        ></Route>
                      </Route>

                      <Route element={<RequireAuth allowedRoles={['Admin']} />}>
                        {/* <Route path="/AddRoute" element={<AddRouteByAutoCompleteForm/>}/> */}
                        <Route
                          path="/AddRoute"
                          element={<TextAreaWithAutocomplete />}
                        />
                        <Route path="/Subagents" element={<SubagentPage />} />
                        <Route
                          path="/CustomLocations"
                          element={<CustomLocations />}
                        />

                        <Route
                          path="/UserRequests"
                          element={<UserRequestsPage />}
                        />
                        <Route
                          path="/register-page"
                          element={<RegisterPage />}
                        />
                        <Route
                          path="/Reservations"
                          element={<AllReservationsPage />}
                        />
                           <Route
                          path="/Rides"
                          element={<RidesPage />}
                        />
                      </Route>
                      <Route path="*" element={<Eror404 />} />
                    </Route>
                  </Routes>
                </Router>
              </ValidationProvider>
            </LocalizationProvider>
          </Suspense>
        </SnackbarProvider>
    </SignalRProvider>
    </RecordsProvider>

  );
}

export default App;
