import { apiSlice } from '../../app/api/apiSlice';

export const ridesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRidesPerPage: builder.query({
      query: (queryDate) =>
        `Ride/GetRidesPerPage?date=${queryDate.date !== null ? queryDate.date.toISOString() : new Date('0001-01-01T00:00:00Z').toISOString()}&routeId=${queryDate.route ? queryDate.route : 0}&direction=${queryDate.direction === 'departure' ? true : queryDate.direction === 'return' ? false : ''}&pageSize=${queryDate.pageSize}&pageNumber=${queryDate.pageNumber}`,
      providesTags: ['ridesPerPage'],
    }),
    manageDrivers: builder.mutation({
      query: (body) => ({
        url: `Ride/ManageDrivers`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['ridesPerPage'],
    }),
    removePassengersFromRide: builder.mutation({
      query: (body) => ({
        url: `Ride/RemovePassengersFromRide`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ridesPerPage'],
    }),
  }),
});

export const { useLazyGetRidesPerPageQuery, useManageDriversMutation,useRemovePassengersFromRideMutation } =
  ridesApiSlice;
