import React, { useEffect, useState } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
  Button,
  Menu,
  MenuItem,
  Avatar,
  Tooltip,
  Container,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import BackpackIcon from '@mui/icons-material/Backpack';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { viewUserById } from '../../services/UserService';
import { logOut } from '../../features/auth/authSlice';
import { Image } from '@mui/icons-material';
import { ReactComponent as Settings } from '../../Icons/settings-01.svg';
import { ReactComponent as Profile } from '../../Icons/user-01.svg';
import { ReactComponent as LogoutIcon } from '../../Icons/logouticon.svg';
import { ReactComponent as NotiifyIcon } from '../../Icons/notification-icon.svg';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import './Appbar.css';
import DrawerBar from './Drawer';
import { useRecords } from '../../store/context/SMSRecordContext';
import { useSignalRConection } from '../../store/context/SignalRContext';
// import i18n from 'i18next';

const ResponsiveAppBar = ({}) => {
  const location = useLocation();
  const { getNumberOfNotifications } = useRecords();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElSubNav, setAnchorElSubNav] = useState(null);
  const [anchorElUserNav, setAnchorElUserNav] = useState(null);

  const [anchorElBurgerMenu, setAnchorElBurgerMenu] = useState(null);
  const navigate = useNavigate();
  const [user, setUser] = useState();
  const [languageState, setLanguageState] = React.useState('srpski');
  const { t, i18n } = useTranslation();
  const isLoggedIn = localStorage.getItem('token');
  const userRole = localStorage.getItem('roles');
  const userId = localStorage.getItem('id');
  const { discconect } = useSignalRConection();

  const dispatch = useDispatch();

  const handleOpenNavSubMenu = (event) => {
    handleCloseNavMenu();
    handleCloseNavSubMenu();
    setAnchorElSubNav(event.currentTarget);
  };

  const handleCloseNavSubMenu = () => {
    setAnchorElSubNav(null);
  };
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  // const handleOpenNavBurgerMenu = (event) => {
  //   setAnchorElBurgerMenu(event.currentTarget);
  // };

  // const handleCloseNavBurgerMenu = () => {
  //   setAnchorElBurgerMenu(null);
  // };

  async function ViewUserById(id) {
    try {
      const user = (await viewUserById(id)).data;
      setUser(user);
      // setOpen(false);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    console.log(userId);
    if (userId != null) {
      ViewUserById(userId);
    }
  }, [userId]);

  // const hangleChangeLanguage = (event) => {
  //   if (languageState === 'srpski') {
  //     setLanguageState('english')
  //     i18n.changeLanguage('en')
  //   } else {
  //     setLanguageState('srpski')
  //     i18n.changeLanguage('rs')
  //   }
  //   console.log('language: ', languageState)
  // }

  const hangleChangeLanguage = () => {
    const currentLanguage = i18n.language;
    console.log(currentLanguage);

    if (languageState === 'srpski') {
      setLanguageState('english');
    } else {
      setLanguageState('srpski');
    }

    const newLanguage = i18n.language === 'en' ? 'rs' : 'en';
    i18n.changeLanguage(newLanguage);
  };

  // const handleMainPageNavigate = () => {
  //   navigate('/CreateBooking');
  // };

  const handleOpenNavMenu = (event) => {
    handleCloseNavSubMenu();
    handleCloseProfileMenu();

    setAnchorElNav(event.currentTarget);
  };
  const handleCloseProfileMenu = (event) => {
    setAnchorElUserNav(null);
  };
  const handleOpenUserMenu = (event) => {
    handleCloseNavSubMenu();
    handleCloseProfileMenu();

    setAnchorElUserNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  // const handleAccountNavigate = async () => {
  //   navigate(`/UserProfile/${userId}`);
  // };

  const handleLogoutNavigate = async () => {
    discconect(userId);

    dispatch(logOut());
    setUser(null);
    navigate('/');
  };

  // const handleNavigation = (path) => () => {

  //   navigate(path);
  //   handleCloseNavMenu();
  //   setAnchorElBurgerMenu(null);
  //   setAnchorElSubNav(null);
  // };

  const handleCloseMenu = () => {
    handleCloseNavMenu();
    handleCloseNavSubMenu();
    handleCloseProfileMenu();
  };

  // const handleCreateBookingNavigate = async () => {
  //   navigate(`/CreateBooking`, {
  //     state: { isEditMode: false },
  //   });
  // };

  const handleOpenInstruction = () => {
    const url =
      'https://docs.google.com/document/d/1x-igp4-bRfQGSTvJQv547vHwn8NRPiIM/edit#heading=h.gjdgxs';
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const isSelected = (path) => location.pathname === path;

  return isLoggedIn ? (
    <AppBar
      sx={{
        backgroundColor: 'white',
        color: 'black',
        zIndex: '90',
        alignItems: 'center',
        boxShadow: 'none !important',
        borderBottom: '1px solid #EAECF0',
      }}
    >
      <Container
        sx={{
          maxWidth: '1280px !important',
          padding: '0px 32px 0px 32px !important',
        }}
      >
        <Toolbar sx={{ padding: '0px !important' }}>
          <NavLink className="header-icon-container" to="/CreateBooking" />

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'flex', md: 'none' },
            }}
          >
            <IconButton
              size="large"
              aria-label="open navigation menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              className="header-menu-container"
              // id="menu-appbar"
              anchorEl={anchorElNav}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              <MenuItem onClick={handleCloseMenu}>
                <NavLink className="menu-link-container" to="/CreateBooking">
                  Booking
                </NavLink>
              </MenuItem>
              {/* <MenuItem onClick={handleCloseMenu}>
                <NavLink className="menu-link-container" to="/Bookings">
                  {t('appbar.rides')}
                </NavLink>
              </MenuItem> */}
              <MenuItem onClick={handleCloseMenu}>
                <NavLink className="menu-link-container" to="/Reservations">
                  {t('appbar.bookingList')}
                </NavLink>
              </MenuItem>

              <MenuItem onClick={handleCloseMenu}>
                <NavLink className="menu-link-container" to="/RoutesPage">
                  {t('route-list.routes')}
                </NavLink>
              </MenuItem>
              <MenuItem onClick={handleCloseMenu}>
                <NavLink className="menu-link-container" to="/Users">
                  {t('appbar.userManagement')}
                </NavLink>
              </MenuItem>
            </Menu>
          </Box>

          <Box
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
            }}
          >
            <NavLink className="header-icon-containe" to="/CreateBooking" />
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: {
                xs: 'none',
                md: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '16px',
              },
            }}
          >
            <NavLink
              className="header-link-container"
              key="Reservations"
              to="/CreateBooking"
            >
              Booking
            </NavLink>
            {/* <NavLink
              className="header-link-container"
              key="Rides"
              to="/Bookings"
            >
              {t('appbar.rides')}
            </NavLink> */}
            <NavLink
              className="header-link-container"
              key="ReservationList"
              to="/Reservations"
            >
              {t('appbar.bookingList')}
            </NavLink>
            <NavLink
              className="header-link-container"
              key="Rides"
              to="/Rides"
            >
              {t('appbar.Rides')}
            </NavLink>
            {userRole === 'Admin' && (
              <>
                <NavLink
                  className="header-link-container"
                  key="Configuration"
                  to="/RoutesPage"
                >
                  {t('route-list.routes')}
                </NavLink>
                <NavLink
                  className="header-link-container"
                  key="UserManagement"
                  to="/Users"
                >
                  {t('appbar.userManagement')}
                </NavLink>
                <div className="menu-settings">
                  <Menu
                    className="header-menu-container"
                    id="basic-menu"
                    anchorEl={anchorElSubNav}
                    open={Boolean(anchorElSubNav)}
                    onClose={handleCloseNavSubMenu}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                  >
                    <MenuItem onClick={handleCloseMenu}>
                      <NavLink
                        className="menu-link-container"
                        to="/PaymentMethods"
                      >
                        {t('appbar.paymentMethod')}
                      </NavLink>
                    </MenuItem>
                    <MenuItem onClick={handleCloseMenu}>
                      <NavLink className="menu-link-container" to="/Subagents">
                        {t('appbar.subagent')}
                      </NavLink>
                    </MenuItem>
                    <MenuItem onClick={handleCloseMenu}>
                      <NavLink
                        className="menu-link-container"
                        to="/CustomLocations"
                      >
                        {t('appbar.customLocations')}
                      </NavLink>
                    </MenuItem>
                  </Menu>
                </div>
              </>
            )}
          </Box>
          <Box
            sx={{
              color: 'red',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <span
              className="header-lenguage-span"
              onClick={hangleChangeLanguage}
            >
              {i18n.language === 'rs' ? 'SRB' : 'ENG'}
            </span>
            {userRole === 'Admin' && (
              <Settings
                style={{ cursor: 'pointer' }}
                onClick={handleOpenNavSubMenu}
                className="settings"
              ></Settings>
            )}
            <div style={{ height: '40px' }} onClick={toggleDrawer(true)}>
              {console.log('num of not:' + getNumberOfNotifications())}
              {getNumberOfNotifications() > 0 && (
                <span className="app-bar-notification-number">
                  {/* || getNumberOfNotifications() === '99+' // if logic in getNumberOfNotifications change */}
                  {getNumberOfNotifications() > 99
                    ? '99+'
                    : getNumberOfNotifications()}
                </span>
              )}
              <NotiifyIcon
                style={{ cursor: 'pointer' }}
                className="settings"
              ></NotiifyIcon>
            </div>
            <Profile
              style={{ cursor: 'pointer' }}
              onClick={handleOpenUserMenu}
              className="settings"
            ></Profile>
            <HelpOutlineIcon
              style={{ cursor: 'pointer' }}

              onClick={handleOpenInstruction}
              className="settings setting-open-drive"
            />

            <div></div>

            {open == true && (
              <DrawerBar open={open} onClose={toggleDrawer(false)}></DrawerBar>
            )}
          </Box>
        </Toolbar>
      </Container>
      <Menu
        className="header-menu-container"
        id="user-menu"
        anchorEl={anchorElUserNav}
        open={Boolean(anchorElUserNav)}
        onClose={handleCloseProfileMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {/* <div  className='menu-itam-user-info' onClick={handleCloseMenu}> */}
        {user && (
          <div className="header-menu-user-info-container">
            <span className="header-name-user-style">
              {user.firstName} {user.lastName}
            </span>
            <span className="header-mail-user-style">{user.userName}</span>
          </div>
        )}
        {/* </div> */}
        <MenuItem onClick={handleCloseMenu}>
          <NavLink
            className="menu-link-container"
            to={`/UserProfile/${userId}`}
          >
            <Profile />
            {t('appbar.myAccount')}
          </NavLink>
        </MenuItem>
        <MenuItem onClick={handleCloseMenu}>
          <span onClick={handleLogoutNavigate} className="menu-link-container">
            <LogoutIcon /> {t('appbar.logout')}
          </span>
        </MenuItem>
      </Menu>
    </AppBar>
  ) : (
    <></>
  );
};

export default ResponsiveAppBar;
