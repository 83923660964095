import { useTranslation } from 'react-i18next';
import FilledButton from '../Button/FilledButton';

export default function FiltersFieldContainer(props) {
  const { children,label,className } = props;
  const { t } = useTranslation();

  return (
    <div style={{minWidth:"200px"}} className={className}>
      <label className="custom-label">
        {label}
      </label>
      {children}
    </div>
  );
}
