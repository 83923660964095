import { createContext, useContext, useEffect, useState } from 'react';
import { BASE_API_URL, groupName } from '../../config';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { addConnection, removeConnection } from '../../services/SignalRService';
import { useRecords } from './SMSRecordContext';
import { useGetSmsRecordsQuery } from '../../features/sms/smsSlice';

const SignalRContext = createContext(null);

export const SignalRProvider = ({ children }) => {
  const userId = localStorage.getItem('id');
  console.log(userId);
  const [connection, setConnection] = useState(null);
  const [isButtonEnabled, setEnabled] = useState(true);
  const { updateRecord } = useRecords();

  const getBaseUrl = () => {
    const removePart = '/api/'; // Part to remove
    return BASE_API_URL.includes(removePart)
      ? BASE_API_URL.replace(removePart, '')
      : BASE_API_URL;
  };
  useEffect(() => {
    const startConnection = async () => {
      try {
        const conn = new HubConnectionBuilder()
          .withUrl(`${getBaseUrl()}/SMSHub`)
          .configureLogging(LogLevel.Information)
          .build();

        console.log('Connection building...');

        conn.onclose(() => {
          console.log('Connection closed. Attempting to reconnect...');
        });

        conn.onreconnected(() => {
          console.log('Connection reestablished.');
        });

        conn.onreconnecting(() => {
          console.log('Reconnecting...');
        });

        conn.on('ConnectionAdded', (response) => {
          console.log(response);
        });
        conn.on('Dissconnected', (response) => {
          console.log(response);
        });

        conn.on('BroadcastSMSResponses', (smsResponses) => {
          console.log(`Received data from the server`, smsResponses);
          const isNull = isSomeDataNull(smsResponses);
          if (!isNull) {
            isSendingPosible(smsResponses)
            updateRecord(smsResponses);
          }
        });

        await conn.start();
        console.log('Connection started');

        setConnection(conn);

        return conn;
      } catch (e) {
        console.error(e);
      }
    };

    startConnection();

    return () => {
      if (connection) {
        connection.off('ConnectionAdded');
        connection.off('BroadcastSMSResponses');
        connection.off('Dissconnected');

        connection.stop();
      }
    };
  }, []);

  const isSomeDataNull = (smsResponses) => {
    const hasNull = smsResponses.smSs.some((response) => response === null);
    if (hasNull) {
      console.error(
        'Error with your device,or you dont have sim card!',
        'error'
      );
    }
    return hasNull;
  };

  useEffect(() => {
    console.log(userId);
    connect(userId);
  }, [connection]);

  const connect = async (userId) => {
    if (userId) {
      await addConnection(userId, connection);
    }
  };
  const discconect = async (id) => {
    if (userId) {
      await removeConnection(id, connection);
    }
  };
  const isSendingPosible=(smsResponses)=>{
    let unsentCount = smsResponses.smSs.filter((sms) => sms.status === 'Unprocessed sms').length;
    console.log("c o n t e x t",unsentCount)
    if(unsentCount!=0){
      setEnabled(false)
    }
    else{
      setEnabled(true)
    }
  }

  return (
    <SignalRContext.Provider value={{ connection, connect,discconect,isButtonEnabled }}>
      {children}
    </SignalRContext.Provider>
  );
};

export const useSignalRConection = () => {
  return useContext(SignalRContext);
};
