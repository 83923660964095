import React, { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useConfirmEmailMutation } from '../features/auth/authApiSlice';
import { useSnackbar } from '../components/SneckBarProvider';
import '../components/ThankYou/ThankYouContent.css'

const PassengerRegistrationConfirmPage = () => {
  localStorage.clear();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("emailToken");
  //const { data, error, isLoading } = useConfirmEmailQuery(token);
  //const [confirm] = useConfirmEmailMutation();
  const [confirm] = useConfirmEmailMutation();

  const showSnackbar = useSnackbar();
  const navigate = useNavigate();

  let spanContent = 'Hvala što ste se registrovali';

  useEffect(() => {
    if (token == undefined || token == "" || token == null) {
      showSnackbar("Just error", "error");

      //console.log(error.data.Message);
      // if (
      //   error.data.Message ==
      //   "This token is already used!"
      // ) {
      //   navigate("/");
      // }
      // if (
      //   error.data.Message ==
      //   "Wrong token!" ||
      //   error.data.Message == "The token has expired!"
      // ) {
      //   navigate("/Error404");
      // }
    } else {
      confirm(token);
    }
  }, [token])
  return (
    <>
      {
        <div className="thankYouContentMainContainer">
          <div className='pngContainer'></div>
          <div className="thankYou-text-container">
            <span>{spanContent}</span>
            {/* {divClassName == 'pngContainer' && ( */}
              <span>Email registracija uspešna</span>
            {/* )} */}
          </div>
        </div>
      }
    </>
  )
}

export default PassengerRegistrationConfirmPage