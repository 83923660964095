import { apiSlice } from '../../app/api/apiSlice';

export const stopSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // sendSMS: builder.mutation({
    //   query: (body) => ({
    //     url: `SMS/NotifyUsers`,
    //     method: 'POST',
    //     body,
    //   }),
    // }),
    addPredefinedTimes: builder.mutation({
      query: (body) => ({
        url: `PredefinedTimes/AddPredefinedTimes`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['directions'],
    }),
    editRestrictedDate: builder.mutation({
      query: ({ routeId, dateOnly, direction }) => ({
        url: `RestrictDates/EditRestrictedDates?routeId=${routeId}&dateonly=${dateOnly}&direction=${direction}`,
        method: 'PUT',
      }),
    }),
    getPredefinedTimes: builder.query({
      query: (routeId) => ({
        url: `PredefinedTimes/GetPredefinedTimesFromRoute?routeId=${routeId}`,
        method: 'GET',
      }),
    }),
    removePredefinedTime: builder.mutation({
      query: (routeId) => ({
        url: `PredefinedTimes/RemovePredefinedTime?id=${routeId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['directions'],
    }),
  }),
});

export const {
  useAddPredefinedTimesMutation,
  useLazyGetPredefinedTimesQuery,
  useRemovePredefinedTimeMutation,
} = stopSlice;
