import { useState } from 'react';
import './PredefinedTime.css';
import PredefinedTimesMenuItem from './PredefinedTimesMenuItem';

export default function PredefinedTimesMenu({predefinedTimes,value,onClick}) {

//   const [open,setOpen]=useState(false);   
    console.log(value);

  return (
    <>
        <div   className='predefined-times-menu-main-container'>
        {predefinedTimes.map((time, index) => (
                <PredefinedTimesMenuItem
                  key={index}
                  time={time}
                  value={value?.format("HH:mm")}
                  onClick={onClick}
                //   onRemovePredefinedTime={onRemovePredefinedTime}
                />
              ))}
            {/* <PredefinedTimesMenuItem /> */}
        </div>

    </>
  );
}
