import * as React from 'react';
import CustomDialog from '../Dialog/CustomDialog.js';
import { UpdateCustomLocation } from './UpdateCustomLocation.jsx';

export default function UpdateCustomLocationDialog(props) {
  const {
    onClose,
    open,
    location,
    onUpdateCustomLocationTable,
    locationForUpdate,
  } = props;

  return (
    <>
      <CustomDialog onOpen={open} onClose={onClose} customMargin="0px">
        <UpdateCustomLocation
          locationForUpdate={locationForUpdate}
          location={location}
          onUpdateCustomLocationTable={onUpdateCustomLocationTable}
          onClose={onClose}
        ></UpdateCustomLocation>
      </CustomDialog>
    </>
  );
}
