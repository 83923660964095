import { apiSlice } from '../../app/api/apiSlice';

export const VehicleApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getVehicles: builder.query({
      query: () => 'Vehicle/GetVehicles',
    }),
    getVehicleById: builder.query({
      query: (id) => `Vehicle/GetVehicle/${id}`,
    }),
    addVehicleToBooking: builder.mutation({
      query: (body) => ({
        url: 'Vehicle/AddVehicleToBooking',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Reservations'],
    }),
  }),
});

export const {
  useLazyGetVehiclesQuery,
  useGetVehiclesQuery,
  useGetVehicleByIdQuery,
  useAddVehicleToBookingMutation,
} = VehicleApiSlice;
