import {
  HubConnectionBuilder,
  LogLevel,
  HttpTransportType,
} from '@microsoft/signalr';
import { useEffect, useState } from 'react';

const GpsTrackerComponent = ({ registrationPlate }) => {
  const [gpsData, setGpsData] = useState(null);
  const [connection, setConnection] = useState(null);

  // Effect for setting up the SignalR connection
  useEffect(() => {
    if (!registrationPlate) return;

    setGpsData(null);
    // Function to set up the new SignalR connection
    const setupConnection = async () => {
      console.log(
        'Setting up SignalR connection for plate:',
        registrationPlate
      );

      const newConnection = new HubConnectionBuilder()
        .withUrl('http://localhost:5129/GpsHub', {
          transport: HttpTransportType.WebSockets,
        })
        .withAutomaticReconnect()
        .configureLogging(LogLevel.Information)
        .build();

      // Stop the previous connection if it exists
      if (connection) {
        console.log('Stopping previous SignalR connection...');
        await connection.stop();
        console.log('Previous connection stopped.');
      }

      setConnection(newConnection);

      try {
        console.log('Starting SignalR connection...');
        await newConnection.start();
        console.log('SignalR connected.');

        // Join the SignalR group based on the registration plate
        console.log(`Joining group for plate: ${registrationPlate}`);
        await newConnection.invoke('JoinGroup', registrationPlate);

        // Listen for GPS data
        newConnection.on('ReceiveGpsData', (data) => {
          console.log('Received GPS data:', data);

          if (data.registrationPlates === registrationPlate) {
            console.log('Setting GPS data...');
            setGpsData(data);
          }
        });
      } catch (err) {
        console.error('SignalR Connection Error:', err);
      }
    };

    setupConnection();

    // Clean up the connection when component unmounts or registrationPlate changes
    return () => {
      if (connection) {
        console.log(
          'Cleaning up SignalR connection for plate:',
          registrationPlate
        );
        connection.stop();
      }
    };
  }, [registrationPlate]); // Re-run the effect when registrationPlate changes

  // Debugging GPS data updates
  useEffect(() => {
    console.log('gpsData updated:', gpsData);
  }, [gpsData]);

  return (
    <div>
      <h1>GPS Tracker for {registrationPlate}</h1>
      {gpsData ? (
        <div>
          <p>
            <strong>Registration Plate:</strong> {gpsData.registrationPlates}
          </p>
          <p>
            <strong>Latitude:</strong> {gpsData.latitude}
          </p>
          <p>
            <strong>Longitude:</strong> {gpsData.longitude}
          </p>
          <p>
            <strong>Speed:</strong> {gpsData.speed} km/h
          </p>
          <p>
            <strong>Status:</strong> {gpsData.status}
          </p>
        </div>
      ) : (
        <p>Waiting for GPS data for {registrationPlate}...</p>
      )}
    </div>
  );
};

export default GpsTrackerComponent;
