
import ClearIcon from '@mui/icons-material/Clear';

export default function AssignedDriver(props) {
  const { driver,onUnassingeDriver } = props;
  const handleUnassigneDriver = (id) => {
    onUnassingeDriver(id);
  };
  return (
    <>
      <div
        className={`assigned-driver-main-container${driver?.type ? '-new' : ''}`}
      >
        <span>{driver.name != ' ' ? driver.name : driver.userName}</span>
        <ClearIcon className='pointer' onClick={() => handleUnassigneDriver(driver.id)} />
      </div>
    </>
  );
}
