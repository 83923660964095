import React from 'react'
import BookingRequestsComponent  from '../components/Booking/BookingRequests/BookingRequests'
import UnapprovedReservations from '../components/Reservations/AddReservation/UnapprovedReservation/UnapprovedReservation'

const BookingRequestsPage = () => {
  return (
    // <BookingRequestsComponent />
    <UnapprovedReservations />
  )
}

export default BookingRequestsPage