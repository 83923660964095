import { useState, useEffect } from "react";
import GoogleMapComponent from "../GoogleMap/GoogleMap";
// import { getDirections } from "../../../services/DirectionService";
// import { sortRouteStops } from "../../../utils/googleMapsHelper/sortRouteStops";
// import { getRouteLength } from "../../../utils/googleMapsHelper/getRouteLength";
import { sortRouteStops } from "../../utils/googleMapsHelper/sortRouteStops";
import { getRouteLength } from "../../utils/googleMapsHelper/getRouteLength";
import { getDirections } from "../../services/DirectionService";
import "./AddCustomLocation.css";
import { TextField, Button } from "@mui/material";
import CustomButton from "../Button/Button";
import {
  newTextFieldStyle,
  textFieldStyles,
} from "../../customStyles/customStyles";
import { useTranslation } from "react-i18next";
import GooglePlacesAutocomplete from "../GoogleMap/GooglePlacesAutocomplete ";
import { geocodeAddress } from "../../utils/googleMapsHelper/geocode";
// import Button from "@mui/material";
import { checkGeocodedAddres } from "../../utils/googleMapsHelper/geocode";
import { useSnackbar } from "../SneckBarProvider";
import AddCustomLocationDialog from "./AddCustomLocationDialog";
import FilledButton from "../Button/FilledButton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import WhiteButton from "../Button/WhiteButton";
import InputAutoComplete from "../GoogleMap/InputAutocomplete";
import { ForkLeft } from "@mui/icons-material";
import { useAddCustomLocationMutation } from "../../features/customlocations/customLocationSlice";
import { CustomLocationsEditor } from "./CustomLocationEditor";

export const AddCustomLocation = (props) => {
  const {
    routeStops,
    origin,
    destination,
    onRightClick,
    checkMap,
    onUpdateCustomLocationTable,
    onClose
  } = props;

  const showSnackbar = useSnackbar();
  const [addCustomLocation] = useAddCustomLocationMutation();
  // const markerPosition = {
  //   lat: locationForUpdate.location.lat,
  //   lng: locationForUpdate.location.long,
  // };
  const [customLocation, setCustomLocation] = useState({
    locationName: "",
    location: {
      lat: "",
      long: "",
      country: "",
      city: "",
      street: "",
      number: "",
    },
  });

  const handleLocationChange = (newLocation) => {
    
    setCustomLocation((prevState) => ({
      ...prevState,
      location: {
        ...prevState.location,
        ...newLocation,
      },
    }));
  };
  const handleLocationNameChange = (event) => {
    const newName = event.target.value;
    setCustomLocation((prevState) => ({
      ...prevState,
      locationName: newName,
    }));
  };

  const handleReset = () => {
    setCustomLocation({
      locationName: "",
      location: {
        lat: "",
        long: "",
        country: "",
        city: "",
        street: "",
        number: "",
      },
    });
  };

  useEffect(() => {
    console.log(customLocation);
  }, [customLocation]);

  const handleAddCustomLocation = async () => {
    
    try {
      if (customLocation.locationName != "") {
        const response = await addCustomLocation(customLocation).unwrap();

        showSnackbar(response?.message, "success");
        onUpdateCustomLocationTable();
      } else {
        showSnackbar("You must choose the name!", "error");
      }
    } catch (err) {
      showSnackbar(err?.data?.Message, "error");
    }
  };


  const handleAdd=()=>{
    console.log("rewrwerwerew")
  }
  // const handleClose = () => {
  //   setOpen(false);
  // };
  return (
    <CustomLocationsEditor
      onResetClcik={handleReset}
      // editMarkerPosition={markerPosition}
      onLocationChange={handleLocationChange}
      onNameChange={handleLocationNameChange}
      customLocation={customLocation}
      onUpdateCustomLocationTable={onUpdateCustomLocationTable}
      onSubmitClick={handleAddCustomLocation}
      onClose={onClose}

    />
  );
};
