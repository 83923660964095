import React, { useState } from 'react';
import {
  Button,
  TextField,
  Collapse,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Card,
} from '@mui/material';
import './ShowAllBookingsComponent.css';
import CustomButton from '../../Button/Button';
import { useTranslation } from 'react-i18next';

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import FiltersContainer from '../../Route/FilterRouteComponent/FilterContainer';
import InputAutoComplete from '../../GoogleMap/InputAutocomplete';
import { newTextFieldStyle } from '../../../customStyles/customStyles';
import CustomTextField from '../../CustomTextFields/CustomTextField';
import { enableble } from '../../../config';
import CustomDropDown from '../../CustomTextFields/CustomDropDown';

const BookingsFilters = (props) => {
  const initialStateFilters = {
    dateFrom: undefined,
    dateTo: undefined,
    code: '',
    startCityName: '',
    endCityName: '',
    startCountryName: '',
    endCountryName: '',
    creatorId: '',
    creatorName: '',

    pageSize: 10,
    pageNumber: 1,
    bookingType: 'all',
    status: true,
  };

  const { t, i18n } = useTranslation();

  const [filters, setFilters] = useState(initialStateFilters);
  const [open, setOpen] = useState(true);
  const [resetChange, setResetChange] = useState(true);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    const sanitizedValue = type === 'date' && value === '' ? undefined : value;

    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: type === 'checkbox' ? checked : sanitizedValue,
    }));
  };

  const handleToggle = () => {
    setOpen(!open);
  };

  const filterBooking = (e) => {
    e.preventDefault();

    console.log('sending', filters);

    props.onReceiveFilteredBooking(filters);
  };

  const resetFilters = (e) => {
    e.preventDefault();
    setFilters(initialStateFilters);
    setResetChange(!resetChange);
  };

  const handleDateChange = (newVal, key) => {
    setFilters((prevVal) => ({
      ...prevVal,
      [key]: newVal ? dayjs(newVal).format('YYYY-MM-DD') : null,
    }));
  };
  const handleNewStartLocation = (stop) => {
    console.log(stop);
    setFilters((prevData) => ({
      ...prevData,
      startCityName: stop.city,
      startCountryName: stop.country,
    }));
  };

  const handleNewEndLocation = (stop) => {
    console.log(stop);
    setFilters((prevData) => ({
      ...prevData,
      endCityName: stop.city,
      endCountryName: stop.country,
    }));
  };
  const handleStartAutocompleteChange = (event) => {
    console.log(event.target.value);
    if (event.target.value === '') {
      setFilters((prevData) => ({
        ...prevData,
        startCityName: '',
        startCountryName: '',
      }));
    }
  };
  const handleEndAutoCompleteChange = (event) => {
    console.log(event.target.value);
    if (event.target.value === '') {
      setFilters((prevData) => ({
        ...prevData,
        endCityName: '',
        endCountryName: '',
      }));
    }
  };

  return (
    // <Card className='main-bookings-filter-container'>
    //     <Button onClick={(e) => handleToggle(e)} className='show-filters-button' sx={{ color: 'rgba(125,25,32,255)', borderColor: 'rgba(125,25,32,255)' }}>
    //         {open ? t('booking-list.hideFilters') : t('booking-list.showFilters')}
    //     </Button>
    //     <Collapse in={open}>
    <FiltersContainer
      onResetClick={(e) => resetFilters(e)}
      onFilterClcik={filterBooking}
    >
      {/* <form onSubmit={filterBooking}> */}
      {/* <Grid container spacing={2} className="grid-container">
          {' '} */}
      {/*alignItems="center" justifyContent="center" padding='15px' */}
      <div className="route-filters-three-fields-container">
        <div>
          <InputAutoComplete
            className="booking-filters-autocomplete-field"
            labelText={t('route-list.startLocation')}
            label={true}
            placeholder={t('route-filters.enterLocation')}
            onLocationChange={handleNewStartLocation}
            useForFiltering={true}
            customStyle={newTextFieldStyle}
            reesetChange={resetChange}
            onChange={handleStartAutocompleteChange}
            hasIcon={true}
          />
        </div>
        <div className="booking-filters-autocomplete-field">
          <InputAutoComplete
            label={true}
            labelText={t('route-list.endLocation')}
            onChange={handleEndAutoCompleteChange}
            placeholder={t('route-filters.enterLocation')}
            reesetChange={resetChange}
            onLocationChange={handleNewEndLocation}
            useForFiltering={true}
            customStyle={newTextFieldStyle}
            hasIcon={true}
          />
        </div>

        {/* <TextField
                                type="date"
                                name="dateFrom"
                                value={filters.dateFrom || ''}
                                onChange={handleChange}
                                label={t('booking-list.dateFrom')}
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            /> */}
        <div className="booking-filter-date-time">
          <label className='custom-label'> {t('booking-list.dateFrom')}</label>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              //   label={t('booking-list.dateFrom')}
              className="filter-input"
              value={
                filters.dateFrom ? dayjs(filters.dateFrom, 'YYYY-MM-DD') : null
              }
              //value={filters.dateFrom || ''}
              onChange={(newVal) => handleDateChange(newVal, 'dateFrom')}
              renderInput={(params) => <TextField {...params} />}
              format="DD/MM/YYYY"
            />
          </LocalizationProvider>
        </div>

        <div className="booking-filter-date-time">
          <label> {t('booking-list.dateTo')}</label>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              // label={t('booking-list.dateTo')}
              value={
                filters.dateTo ? dayjs(filters.dateTo, 'YYYY-MM-DD') : null
              }
              //value={filters.dateFrom || ''}
              onChange={(newVal) => handleDateChange(newVal, 'dateTo')}
              renderInput={(params) => <TextField {...params} />}
              format="DD/MM/YYYY"
            />
          </LocalizationProvider>
        </div>
      </div>

      <div className="route-filters-three-fields-container">
        <CustomTextField
          type="text"
          name="creatorName"
          value={filters.creatorName}
          onChange={handleChange}
          label={t('booking-list.creatorName')}
          sx={newTextFieldStyle}
          placeholder={t('booking-list.creatorName')}
        />
        {/* <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Status</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            defaultValue={filters.status}
            value={filters.status}
            label={t('main.status')}
            name="status"
            onChange={handleChange}
          >
            <MenuItem value={true}>{t('main.available')}</MenuItem>
            <MenuItem value={false}>{t('main.unavailable')}</MenuItem>
          </Select>
        </FormControl> */}

        {/* <div style={{ minWidth: '150px' }}>
          <label className="custom-label">{t('main.status')}</label>
          <select
            name="status"
            id="demo-simple-select"
            value={filters.status}
            onChange={handleChange}
            style={{ ...newTextFieldStyle }}
          >
            <option value={true}>{t('main.available')} </option>
            <option value={false}>{t('main.unavailable')} </option>
          </select>
        </div> */}

        {/* <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">
            {t('booking-list.rideType')}
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            defaultValue={filters.bookingType}
            value={filters.bookingType}
            name="bookingType"
            label={t('booking-list.rideType')}
            onChange={handleChange}
          >
            <MenuItem value="all">{t('main.all')}</MenuItem>
            <MenuItem value="enabled">{t('main.enabled')}</MenuItem>
            <MenuItem value="disabled">{t('main.disabled')}</MenuItem>
          </Select>
        </FormControl> */}
        <div style={{ minWidth: '150px' }}>
          <label className="custom-label">{t('booking-list.rideType')}</label>
          <select
            name="bookingType"
            id="demo-simple-select"
            value={filters.bookingType}
            onChange={handleChange}
            style={{ ...newTextFieldStyle }}
          >
            <option value="all">{t('main.all')}</option>
            <option value="enabled">{t('main.enabled')} </option>
            <option value="disabled">{t('main.disabled')}</option>
          </select>
        </div>
      </div>

      {/* <Grid item xs={12} md={4}>
            <CustomTextField
              type="text"
              name="creatorName"
              value={filters.creatorName}
              onChange={handleChange}
              label={t('booking-list.creatorName')}
              sx={newTextFieldStyle}
            placeholder={t('booking-list.creatorName')}

            />
          </Grid> */}
      {/* <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                defaultValue={filters.status}
                value={filters.status}
                label={t('main.status')}
                name="status"
                onChange={handleChange}
              >
                <MenuItem value={true}>{t('main.available')}</MenuItem>
                <MenuItem value={false}>{t('main.unavailable')}</MenuItem>
              </Select>
            </FormControl>
          </Grid> */}
      {props.role == 'Admin' ? <Grid item xs={12} md={4}></Grid> : <></>}
      {/* <Grid item xs={2} >
                            <CustomButton type="filter" onClick={filterBooking}>
                                {t('booking-list.filterRides')}
                            </CustomButton>
                        </Grid>

                        <Grid item xs={2} >
                            <Button onClick={(e) => resetFilters(e)} variant="outlined" className='reset-filters-button'
                                sx={{ color: 'rgba(125,25,32,255)', borderColor: 'rgba(125,25,32,255)' }} // ????????????????????
                            >
                                {t('booking-list.resetFilters')}
                            </Button>
                        </Grid> */}
      {/* </Grid> */}
      {/* </form> */}
    </FiltersContainer>
    //     </Collapse>
    // </Card>
  );
};

export default BookingsFilters;
