import { useEffect } from 'react';
import { useLazyGetUsersPerRoleQuery } from '../../features/users/UsersApiSlice';
import FiltersContainer from '../Route/FilterRouteComponent/FilterContainer';
import { useTranslation } from 'react-i18next';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useDispatch, useSelector } from 'react-redux';
import { setFilters, resetFilters } from '../../store/Redux/ridesFiltersSlice';
import { useLazyGetRoutesAndDirectionsQuery } from '../../features/routes/routesSlice';
import FiltersFieldContainer from '../ui/FiltersFieldContainer';

export default function RidesFilters({ onReset,onFilterClcick }) {
  const dispatch = useDispatch();
  const { filters } = useSelector((state) => state.ridesFilters);
  const { t } = useTranslation();
  const [getRoutesAndDirections, { data: routes }] =
    useLazyGetRoutesAndDirectionsQuery();

  useEffect(() => {
    getRoutesAndDirections();
  }, []);

  const handleResetFilters = () => {
    onReset();
  };

  const filterBooking = () => {
    console.log(filters);
  };

  const handleChange = (nevVlue, name) => {
    dispatch(setFilters({ [name]: nevVlue }));
  };
  const handleChangeDate = (nevVlue, name) => {
    console.log(nevVlue)

    dispatch(setFilters({ "date": nevVlue}));
  };
  const handleChangeRoute = (nevVlue, name) => {
    if (nevVlue === undefined) {
      dispatch(setFilters({ direction: '' }));
    }
    dispatch(setFilters({ [name]: nevVlue }));
  };

  const getRouteDirections = () => {
    var route = routes?.find((rs) => rs.id == filters.route);
    return route?.directions;
  };
  
  return (
    <>
      <FiltersContainer
        onResetClick={handleResetFilters}
        onFilterClcik={onFilterClcick}
        opened={true}
      >
        <FiltersFieldContainer
          className={
            'booking-filter-date-time reservation-filters-rop-down-container'
          }
          label={t('booking-list.dateFrom')}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              className="filter-input"
              value={filters.date}
              name="date"
              onChange={(newVal) => handleChangeDate(newVal, 'date')}
              format="DD/MM/YYYY"
              slots={{
                textField: TextField,
                // openPickerButton:ExpandIcon
              }}
            />
          </LocalizationProvider>
        </FiltersFieldContainer>
        <FiltersFieldContainer
          className={
            'passangers-fields-container reservation-filters-rop-down-container'
          }
          label={t('reservationsFilters.chooseRoute')}
        >
          {routes && (
            <Autocomplete
              disablePortal
              id="route-autocomplete"
              fullWidth
              options={routes || []}
              value={routes.find((rs) => rs.id == filters.route) || null}
              getOptionLabel={(option) => option.title}
              getOptionKey={(option) => option.id}
              sx={{ padding: '0px' }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={t('reservationsFilters.chooseRoute')}
                />
              )}
              onChange={(event, newValue) =>
                handleChangeRoute(newValue?.id, 'route')
              }
            ></Autocomplete>
          )}
        </FiltersFieldContainer>
        <FiltersFieldContainer
          className={'reservation-filters-rop-down-container'}
          label={t('reservationsFilters.direction')}
        >
          <div className="overided-select">
            <FormControl disabled={filters.route === undefined} fullWidth>
              {!filters.direction && (
                <InputLabel id="demo-simple-select" shrink={false}>
                  {t('reservationsFilters.lineType')}
                </InputLabel>
              )}
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="direction"
                value={filters.direction || ''}
                onChange={(event) =>
                  handleChange(event.target.value, event.target.name)
                }
                placeholder={t('reservationsFilters.lineType')}
                sx={{ padding: '0px !important' }}
              >
                {getRouteDirections()?.map((direction, index) => {
                  return (
                    <MenuItem key={index} value={direction.direction}>
                      {direction.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        </FiltersFieldContainer>
      </FiltersContainer>
    </>
  );
}
