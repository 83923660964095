import React from 'react'
import RegisterForm from '../components/Register/RegisterForm'
import Rides from '../components/Rides/Rides'

const RidesPage = () => {
  return (
    <Rides />
  )
}

export default RidesPage