import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Fragment, useState } from 'react';
import { PassengersTable } from './PassengersTable';
import {
  Collapse,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
} from '@mui/material';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';

import { max } from 'date-fns';
import { useTranslation } from 'react-i18next';
import ManageDriversDialog from './ManageDriversDialog';
import moment from 'moment/moment';

export function RideRow(props) {
  const { ride } = props;
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const { t } = useTranslation();
  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleOpenDriverManagerDialog = () => {
    setOpenDialog(true);
  };

  const showDrivers = () => {
    var driversString = '';
    if (ride.drivers.length > 0) {
      ride.drivers.forEach((driver) => {
        var driverName = driver.name == ' ' ? driver.userName : driver.name;
        driversString =
          driversString !== ''
            ? driversString + ' / ' + driverName
            : driversString + driverName;
      });
    }
    return driversString;
  };
  const checkPassengersLength = () => {
    return ride.passengers.length > 0 ? true : false;
  };

  const actions = (
    <>
      <TableCell className="pointer">
        <Tooltip title={t('rides.menageDrivers')}>
          <ManageAccountsOutlinedIcon />
        </Tooltip>
      </TableCell>
    </>
  );

  return (
    <>
      <Fragment>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell>
            {checkPassengersLength() && (
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            )}
          </TableCell>
          <TableCell>{ride.id}</TableCell>
          <TableCell>{ride.vehicle.registrationPlates}</TableCell>
          {/* <TableCell>{ride.direction}</TableCell> */}
          <TableCell>{ride.directinString}</TableCell>

          <TableCell>{showDrivers()}</TableCell>
          <TableCell>{moment(ride.departure).format('DD/MM/YYYY')}</TableCell>
          <TableCell>
            {moment(ride.departureTime, 'HH:mm:ss').format('HH:mm')}
          </TableCell>
          <TableCell className="pointer">
            <Tooltip title={t('rides.menageDrivers')}>
              <ManageAccountsOutlinedIcon
                onClick={() => handleOpenDriverManagerDialog(ride.id)}
              />
            </Tooltip>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ padding: 0 }} colSpan={8}>
            {checkPassengersLength() && (
              <Collapse in={open} timeout="auto" unmountOnExit>
                {/* <p style={{textAlign:"left",padding:"10px",margin:"0px"}}>Passengers</p> */}

                <PassengersTable
                  passengers={ride.passengers}
                  rideId={ride.id}
                />
              </Collapse>
            )}
          </TableCell>
        </TableRow>
      </Fragment>
      {openDialog && (
        <ManageDriversDialog
          drivers={ride.drivers}
          rideId={ride.id}
          open={openDialog}
          onClose={handleClose}
        />
      )}
    </>
  );
}
