import { useState, useEffect } from 'react';
import CustomDialog from '../Dialog/CustomDialog';
import { useSnackbar } from '../SneckBarProvider';
import { useLazyGetDirectionsAndRestrictedDatesQuery } from '../../features/routes/routesSlice';
import { useTranslation } from 'react-i18next';
import ClearIcon from '@mui/icons-material/Clear';
import './../RestrictDates/Calendar.css';
import PredefinedTimePicker from './PredefinedTimePicker';
import DirectionFormControll from '../CustoFormControl/DirectionFormControll';
import PredefinedTimeBadge from './PredefinedTimeBadge';
import PredefinedTimes from './PredefinetTimes';
import FilledButton from '../Button/FilledButton';
import WhiteButton from '../Button/WhiteButton';
import useRouteData from '../../hooks/useRouteDetails';
import {
  useAddPredefinedTimesMutation,
  useRemovePredefinedTimeMutation,
} from '../../features/predefinedTime/predefinedTimeSlice';
import { Info } from '@mui/icons-material';
import ComfirmationDialog from '../Dialog/ConfirmationDialog';

export default function PredefinedTimeDialog(props) {
  //   const fileName = "add-route-auto-complete";
  const { onClose, open, routeId } = props;
  const {
    directions,
    restrictedDates,
    loading,
    error,
    setSelectedDirection,
    selectedDirection,
    selectedPredefinedTimes,
    refachRestrictedDates,
  } = useRouteData(routeId);
  const [addPredefinedTimes] = useAddPredefinedTimesMutation();
  const [deletePredefinedTime] = useRemovePredefinedTimeMutation();

  const { t } = useTranslation();
  const showSnacbar = useSnackbar();
  // const [selectedDirection, setSelectedDirection] = useState();
  const [newTime, setNewTime] = useState();
  const [predefinedTimes, setPredefinedTimes] = useState([]);
  const [openConf,setOpen]=useState(false);

  // useEffect(() => {
  //   if (directions.length>0) {
  //     console.log(directions);

  //     setSelectedDirection(directions[0].direction);
  //   }
  // }, [directions]);
  useEffect(() => {
    console.log(selectedPredefinedTimes);
    if (selectedPredefinedTimes) {
      setPredefinedTimes(selectedPredefinedTimes);
    }
  }, [selectedPredefinedTimes]);

  const handleChange = (event) => {
    console.log(event.target.value);
    setSelectedDirection(event.target.value);
  };

  const handleCreateDate = (newTime) => {
    const timeExists = predefinedTimes.some((t) => t.time === newTime.time);
    if (!timeExists) setPredefinedTimes((prevTimes) => [newTime, ...prevTimes]);
    else {
      showSnacbar('This time already exists!', 'info');
    }
  };
  const handleRemovePredefinedTime = (time) => {
    console.log(time);
    setPredefinedTimes((prevTimes) =>
      prevTimes.filter((x) => x.id !== time.id)
    );
    if (time.id != time.time) {
      handleDeletePredefinedTime(time.id);
    }
  };

  const handleAddPredefinedTimes = async (date) => {
    try {
      var response = await addPredefinedTimes(date).unwrap();
      refachRestrictedDates();
      handleCloseDialog();      // onClose()
      // onEdit();
      showSnacbar(response?.message, 'success');
    } catch (error) {
      showSnacbar(error?.data?.Message, 'error');
      console.log(error?.data?.Message);
    }
  };
  const handleDeletePredefinedTime = async (id) => {
    try {
      var response = await deletePredefinedTime(id).unwrap();
      refachRestrictedDates();
      // onClose()
      // onEdit();
      showSnacbar(response?.message, 'success');
    } catch (error) {
      showSnacbar(error?.data?.Message, 'error');
      console.log(error?.data?.Message);
    }
  };

  const onAddPredefinedTimesClcik = () => {
    const filteredTimes = predefinedTimes
      .filter((timeObj) => timeObj.time === timeObj.id)
      .map(({ id, ...rest }) => rest);
    console.log(filteredTimes);
    if (filteredTimes != null) {
      handleAddPredefinedTimes(filteredTimes);
    }
    handleCloseDialog();      // onClose()

  };
  const handleClearAll = () => {
    setPredefinedTimes((prevTimes) =>
      prevTimes.filter((timeObj) => timeObj.time !== timeObj.id)
    );
  };
  const handleOpenConfDialog=()=>{
    setOpen(true);
  }
  const handleCloseDialog=()=>{
    setOpen(false)
  }

  return (
    <>
      {directions && (
        <CustomDialog onOpen={open} onClose={onClose} customMargin="0px">
          <div className="restrit-date-header-container">
            {t('predefinedTimes.header')}
            <ClearIcon style={{ cursor: 'pointer' }} onClick={onClose} />
          </div>
          <div className="predefined-time-dialog-main-container">
            {selectedDirection && (
              <>
                <DirectionFormControll
                  directions={directions}
                  value={selectedDirection}
                  onChange={handleChange}
                />
                <PredefinedTimePicker
                  routeId={routeId}
                  direction={selectedDirection}
                  onCreateNewDate={handleCreateDate}
                />
              </>
            )}

            <PredefinedTimes
              onRemovePredefinedTime={handleRemovePredefinedTime}
              predefinedTimes={predefinedTimes}
              newTime={newTime}
            />
            <div className="predefined-times-buutons-cotainer">
              <WhiteButton isValid={true} onClick={handleClearAll}>
                {t('main.cancel')}
              </WhiteButton>
              {selectedPredefinedTimes && (
                <FilledButton
                  onClick={handleOpenConfDialog}
                  isValid={predefinedTimes.some(
                    (timeObj) => timeObj.time === timeObj.id
                  )}
                >
                  {t('main.save')}
                </FilledButton>
              )}
            </div>
          </div>

          {/* </div> */}
        </CustomDialog>
      )}
        {openConf && (
        <ComfirmationDialog
          open={openConf}
          onClose={handleCloseDialog}
          dialogContent={"addPredefinedTimes"}
          onNo={handleCloseDialog}
          onYes={onAddPredefinedTimesClcik}
        />
      )}
    </>
  );
}
