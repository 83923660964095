import React, { useEffect, useState } from 'react';
import {
  Button,
  TextField,
  Collapse,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Card,
  Typography,
  Divider,
  Checkbox,
  FormControlLabel,
  Autocomplete,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  InputAdornment,
  IconButton,
  Box,
  SvgIcon,
} from '@mui/material';
import './AllReservationsComponent.css';
import CustomButton from '../../Button/Button';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useGetRoutesListForDropDownQuery } from '../../../features/bookings/bookingsApiSlice';
import { useTranslation } from 'react-i18next';
import { swapRouteName } from '../../Booking/AddBooking/CreateBookingComponent';
import '../../../customStyles/globalDesignStyles.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  checkboxStyle,
  newTextFieldStyle,
} from '../../../customStyles/customStyles';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useGetUserByIdQuery } from '../../../features/users/UsersApiSlice';
import ExpandIcon from '../../../utils/icons/ExpandIcon';
import { Label, TroubleshootSharp } from '@mui/icons-material';
import FiltersContainer from '../../Route/FilterRouteComponent/FilterContainer';
import CardContainer from './CardContainer';
import CustomTextField from '../../CustomTextFields/CustomTextField';
import { useGetOnlyTitlesQuery, useLazyGetDirectionsQuery } from '../../../features/routes/routesSlice';
import { getDirections } from '../../../services/DirectionService';
import PhoneTextField from '../../CustomTextFields/PhoneTextField';
import PhoneInput from 'react-phone-input-2';

export const sortRoutesByTitle = (routes) => {
  return routes.slice().sort((a, b) => {
    if (a.title < b.title) {
      return -1;
    }
    if (a.title > b.title) {
      return 1;
    }
    return 0;
  });
};

const ReservationsFilters = (props) => {
  const { t, i18n } = useTranslation();
  const role = localStorage.getItem('roles');
  const userId = localStorage.getItem('id');
  const { data: userForId } = useGetUserByIdQuery(userId);

  const initialStateFilters = {
    date: null,
    bookingId: undefined,
    routeId: undefined,
    direction: '',
    isReturnTicket: undefined,
    //isNightLine: undefined,
    nightLine: false,
    createdFrom: null,
    createdTo: null,
    creator: undefined,
    passengerName:
      role == 'Subagent'
        ? userForId?.firstName + ' ' + userForId?.lastName
        : '',
    passengerPhoneNumber: undefined,
    reservationId: '',
    reservationType: 'all',
    //nightLine: false,
    pageSize: 25,
    pageNumber: 1,
  };

  const [filters, setFilters] = useState(initialStateFilters);
  const [open, setOpen] = useState(true);

  const { data: routes = [], isLoading } = useGetOnlyTitlesQuery();
  const [directionDisabled, setDirectionDisabled] = useState(true);
  const [selectedDirections, setSelectedDirections] = useState([]);
  const [expandedFrame, setExpandedFrame] = useState(true);
  const [reservationNumberError, setReservationNumberError] = useState({
    message: '',
    isError: false,
  });
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [sortedRoutes, setSortedRoutes] = useState([]);
  const [getPredictions, { data }] = useLazyGetDirectionsQuery();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    let sanitizedValue;
    sanitizedValue = type === 'date' && value === '' ? undefined : value;

    console.log('new val: ' + sanitizedValue)
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: type === 'checkbox' ? !checked : sanitizedValue,
      reservationId: '',
    }));
  };

  const handlePhoneNumberChange = (phoneNumber) => {
    console.log('Phone number: ' + phoneNumber);
    setFilters((prevFilters) => ({
      ...prevFilters,
      passengerPhoneNumber: phoneNumber,
      reservationId: '',
    }));
  };

  const handleChangeRoute = (e, newValue) => {
    let selectedRoute;

    if (newValue == null) {
      selectedRoute = '';
      setSelectedRoute(null);

      setDirectionDisabled(true);
      // setSelectedDirections([]);

      setFilters((prevFilters) => ({
        ...prevFilters,
        routeId: 0,
        direction: '',
      }));
    } else {
      selectedRoute = routes.find((route) => route.id === newValue.id).title;

      console.log(newValue)
      getPredictions(newValue.id)

      setDirectionDisabled(false);

      setFilters((prevFilters) => ({
        ...prevFilters,
        routeId: newValue.id,
        direction: '',
      }));
    }
  };
  useEffect(() => {
    if (data) {
      console.log(data)
      setDirectionDisabled(false);

      setSelectedDirections([
        { direction: data.departure.direction, name: data.departure.name },
        { direction: data.return.direction, name: data.return.name },
      ]);
    }
  }, [data])

  const handleSelect = (e) => {
    const value = e.target.value;
    if (value.charAt(0) === '#')
      setReservationNumberError({
        message: t('reservationsFilters.enterNumber'),
        isError: true,
      });
    else setReservationNumberError({ message: '', isError: false });
    setFilters({ reservationId: e.target.value });
  };

  const handleChangeNightLine = (e) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      nightLine: !e.target.value,
    }));
  };

  const filterReservation = (e) => {
    e.preventDefault();
    if (role == 'Subagent') {
      //filters.passengerName = userForId.firstName + ' ' + userForId.lastName; //TO DO add filter for creator not passengerName
      props.onReceiveFilteredReservation(filters);
      return;
    } else {
      console.log('fiters', filters);
      props.onReceiveFilteredReservation(filters);
    }
  };

  const resetFilters = (e) => {
    e.preventDefault();
    setFilters({ ...initialStateFilters });
    props.onReceiveFilteredReservation(initialStateFilters);
    setSelectedRoute(null);
    setDirectionDisabled(true);

    // setFilters((prevFilters) => ({
    //   ...prevFilters,
    //   routeId: 0,
    //   direction: '',
    // }));
    // setSelectedDirections([]);
  };

  useEffect(() => {
    const sorted = sortRoutesByTitle(routes);
    setSortedRoutes(sorted);
  }, [routes]);

  const handleDateChange = (newVal, key) => {
    setFilters((prevVal) => ({
      ...prevVal,
      [key]: newVal ? dayjs(newVal).format('YYYY-MM-DD') : null,
    }));
  };

  const titleMappings = {
    'Beograd - Umag': 'Adria Summit',
    'Beograd (Arena) - Valjevo': 'Kamp Vanje Grbića',
    'Beograd - Beč': 'Budimpešta-Beč',
  };

  return (
    <FiltersContainer
      onResetClick={resetFilters}
      onFilterClcik={filterReservation}
      opened={true}
    >
      {/* <AccordionDetails className="accordion-details"> */}
      {/* <Collapse in={open}> */}
      <div className="cards">
        <CardContainer
          type="all"
          description={t('reservations.totalReservations')}
          reservationsCount={props?.total}
          maskClass="reservation-filters-image-mask-all"
        />
        <CardContainer
          type="canceled"
          description={t('reservations.totalConfirmedReservations')}
          reservationsCount={props?.cancelled}
          maskClass="reservation-filters-image-mask-canceled"
        />
        <CardContainer
          type="uncaceled"
          description={t('reservations.totalCancelledReservations')}
          reservationsCount={props?.uncancelled}
          maskClass="reservation-filters-image-mask-uncanceled"
        />
      </div>
      <form
        className="reservation-filters-form-container"
        onSubmit={filterReservation}
      >
        <div className="reservation-filters-row-container">
          <div className='reservation-filters-rop-down-container' style={{ minWidth: '45%' }}>
            <CustomTextField
              name="passengerName"
              label={t('reservations.filterPassengerName')}
              type="text"
              sx={newTextFieldStyle}
              value={filters.passengerName || ''}
              onChange={handleChange}
              placeholder="Marko Tomašević"
            />

          </div>

          <div className='reservation-filters-rop-down-container' style={{ minWidth: '45%' }}>
            <label className="custom-label">{t("main.phone")}</label>

            <PhoneInput
              placeholder = '+381641234567'
              enableSearch={true}
              country={"rs"}
              className="phone-s"
              inputStyle={{
                width: "100%",
                color: "#667085",
              }}
              value={filters.passengerPhoneNumber || ''}
              onChange={(value) => handlePhoneNumberChange(value)}
            />
          </div>

          <div className='reservation-filters-rop-down-container' style={{ alignItems: 'center' }} >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                textWrap: 'wrap',
                width: '50px',
              }}
            >
              <label className="custom-label">{t('booking.nightStart')}</label>
              <Checkbox
                color="error"
                onClick={handleChangeNightLine}
                value={filters.nightLine || ''}
                checked={filters.nightLine}
                style={checkboxStyle}
              ></Checkbox>

            </div>
          </div>

        </div>

        <div className="reservation-filters-row-container">
          <div className="booking-filter-date-time reservation-filters-rop-down-container">
            <label className="custom-label">
              {' '}
              {t('booking-list.dateFrom')}
            </label>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                // label={t('reservationsFilters.date')}
                className="filter-input"
                value={filters.date ? dayjs(filters.date, 'YYYY-MM-DD') : null}
                onChange={(newVal) => handleDateChange(newVal, 'date')}
                renderInput={(params) => <TextField {...params} />}
                format="DD/MM/YYYY"
                slots={{
                  openPickerIcon: ExpandIcon,
                }}
              />
            </LocalizationProvider>
          </div>
          <div className="passangers-fields-container reservation-filters-rop-down-container">
            <label className="custom-label">
              {t('reservationsFilters.chooseRoute')}
            </label>
            <Autocomplete
              disablePortal
              id="route-autocomplete"
              fullWidth
              options={sortedRoutes.filter((r) => r.isEnabled)}
              value={routes.find((rs) => rs.id == filters.routeId) || null}
              getOptionLabel={(option) => option.title}
              getOptionKey={(option) => option.id}
              sx={{ padding: '0px' }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={t('reservationsFilters.chooseRoute')}
                />
              )}
              onChange={handleChangeRoute}
              PaperComponent={({ children }) => {
                return <Paper className="cities-dropdown">{children}</Paper>;
              }}
            ></Autocomplete>
          </div>
          <div className="reservation-filters-rop-down-container">
            <label className="custom-label">
              {t('reservationsFilters.direction')}
            </label>
            <div className="overided-select">
              <FormControl disabled={directionDisabled} fullWidth>
                {!filters.direction && (
                  <InputLabel id="demo-simple-select" shrink={false}>
                    {t('reservationsFilters.lineType')}
                  </InputLabel>
                )}
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="direction"
                  value={filters.direction || ''}
                  onChange={handleChange}
                  placeholder={t('reservationsFilters.lineType')}
                  sx={{ padding: '0px !important' }}
                >
                  {selectedDirections.map((route, index) => {
                    // console.log(selectedDirections)
                    return (
                      <MenuItem key={index} value={route.direction}>
                        {route.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="reservation-filters-rop-down-container">
            <label className="custom-label">
              {t('reservationsFilters.cardType')}
            </label>
            <div className="overided-select">
              <FormControl fullWidth>
                {!filters.isReturnTicket && (
                  <InputLabel id="demo-simple-select-ticket" shrink={false}>
                    {t('reservationsFilters.isReturnTicket')}
                  </InputLabel>
                )}
                <Select
                  labelId="demo-simple-select-ticket"
                  id="demo-simple-select"
                  name="isReturnTicket"
                  value={filters.isReturnTicket || ''}
                  onChange={handleChange}
                >
                  <MenuItem value={'true'}>
                    {t('reservationsFilters.returnTicket')}
                  </MenuItem>
                  <MenuItem value={'false'}>
                    {t('reservationsFilters.oneWay')}
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>

        </div>


        <div className="reservation-filters-row-container">
          <div className="booking-filter-date-time reservation-filters-rop-down-container">
            <label className="custom-label">
              {' '}
              {t('reservationsFilters.createdFrom')}
            </label>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                className="filter-input"
                value={
                  filters.createdFrom
                    ? dayjs(filters.createdFrom, 'YYYY-MM-DD')
                    : null
                }
                onChange={(newVal) => handleDateChange(newVal, 'createdFrom')}
                renderInput={(params) => <TextField {...params} />}
                format="DD/MM/YYYY"
                slots={{
                  openPickerIcon: ExpandIcon,
                }}
              />
            </LocalizationProvider>
          </div>
          <div className="booking-filter-date-time reservation-filters-rop-down-container">
            <label className="custom-label">
              {t('reservationsFilters.createdTo')}
            </label>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                className="filter-input"
                value={
                  filters.createdTo
                    ? dayjs(filters.createdTo, 'YYYY-MM-DD')
                    : null
                }
                onChange={(newVal) => handleDateChange(newVal, 'createdTo')}
                renderInput={(params) => <TextField {...params} />}
                format="DD/MM/YYYY"
                slots={{
                  openPickerIcon: ExpandIcon,
                }}
              />
            </LocalizationProvider>
          </div>

          <CustomTextField
            name="creator"
            label={t('reservationsFilters.created')}
            type="text"
            sx={newTextFieldStyle}
            value={filters.creator || ''}
            onChange={handleChange}
            placeholder={t('reservationsFilters.passengerName')}
          />


          <div className="input-container">
            <CustomTextField
              name="reservationId"
              label={t('reservationsFilters.reservationNumberLabel')}
              type="text"
              sx={newTextFieldStyle}
              value={filters.reservationId || ''}
              onChange={handleSelect}
              placeholder={t('reservationsFilters.reservationNumber')}
            />
            {reservationNumberError.isError && (
              <small className="error-message">
                {reservationNumberError.message}
              </small>
            )}
          </div>

        </div>
      </form>
    </FiltersContainer>
  );
};

export default ReservationsFilters;
