import { apiSlice } from '../../app/api/apiSlice';

export const stopSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    sendSMS: builder.mutation({
      query: (body) => ({
        url: `SMS/NotifyUsers`,
        method: 'POST',
        body,
      }),
    }),

    resendMessages: builder.mutation({
      query: (body) => ({
        url: `SMS/ResendMessages`,
        method: 'POST',
        body,
      }),
    }),

    confirmReservation: builder.mutation({
      query: (token) => ({
        url: `SMS/ConfirmReservation`,
        method: 'PUT',
        params: { token },
      }),
    }),

    editDisplayStatus: builder.mutation({
      query: (id) => ({
        url: `SMS/EditDisplayStatus`,
        method: 'PUT',
        params: { id },
      }),
    }),

    clearAll: builder.mutation({
      query: (listOfIds) => ({
        url: `SMS/ClearAll`,
        method: 'PUT',
        body: listOfIds,  // Send the list of IDs as the request body

      }),
    }),
    getSmsRecords: builder.query({
      query: () => ({
        url: `SMS/GetRecords`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useSendSMSMutation,
  useConfirmReservationMutation,
  useGetSmsRecordsQuery,
  useEditDisplayStatusMutation,
  useClearAllMutation,
  useResendMessagesMutation,
} = stopSlice;
