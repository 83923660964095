import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, TextField } from '@mui/material';
import { useUpdatePaymentMethodMutation } from '../../../features/paymentmethod/PaymentMethodSlice';
import './PaymentMethodEditForm.css';

export default function EditPaymentMethodForm({
    onSuccess,
    getPaymentMethodAfterEdit,
    paymentMethodId,
    selectedPaymentMethod
}) {
    const [paymentMethodName, setPaymentMethodName] = useState(selectedPaymentMethod);
    const [errors, setErrors] = useState({});
    const [t, i18n] = useTranslation();
    const [updatePaymentMethod, { isSuccess, isLoading, isError, error }] =
        useUpdatePaymentMethodMutation();

    const handleInputChange = (event) => {
        setPaymentMethodName(event.target.value);
    };
    const validate = () => {
        const errors = {};
        if (!paymentMethodName.trim()) {
            errors.paymentMethodName = t('paymentMethod.isRequired');
        }
        setErrors(errors);
        return Object.keys(errors).length === 0;
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!validate()) return;
        try {
            const newPaymentMethod = { name: paymentMethodName };
            await updatePaymentMethod({
                body: newPaymentMethod,
                id: paymentMethodId
            }).unwrap();
            if (onSuccess) {
                onSuccess();
            }
            if (getPaymentMethodAfterEdit) {
                getPaymentMethodAfterEdit();
            }
        } catch (err) {
            console.error('Failed to create subagent: ', err);
        }
        setPaymentMethodName('');
    };
    return (
        <Box component="form" onSubmit={handleSubmit}>
            <TextField
                id="subagent-name"
                label={t('paymentMethod.name')}
                variant="outlined"
                value={paymentMethodName}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                error={!!errors.paymentMethodName}
                helperText={errors.paymentMethodName}
            />
            <Button type="submit" color="primary" className='edit-button'>
                {isLoading
                    ? t('paymentMethod.updating')
                    : t('paymentMethod.update')}
            </Button>
            {isError && (
                <p>
                    {t('subagent.error')}: {error.toString()}
                </p>
            )}
        </Box>
    );
}
