import React, { useState } from 'react'
import CreateBookingComponent from '../AddBooking/CreateBookingComponent'
import { useEffect } from 'react'
import { useGetReservationByIdQuery, useGetReservationsQuery, useGetSpecificPassengerReservationByIdQuery } from '../../../features/reservation/reservationSlice'
import { useLocation, useParams } from 'react-router-dom'
import i18n from 'i18next'; // Ensure i18n is imported correctly
import { useTranslation } from 'react-i18next'

const EditBookingComponent = () => {
    //const { lang, reservationId, passengerId } = useParams();
    const { lang, reservationId } = useParams();

    const { i18n } = useTranslation();
    //const { data, isLoading, error } = useGetSpecificPassengerReservationByIdQuery({ reservationId, passengerId });

    const { data, isLoading, error } = useGetReservationByIdQuery({ id:reservationId });

    const [reservationData, setReservationData] = useState(null);

    useEffect(() => {
        if (lang) {
            i18n.changeLanguage(lang);
        }
    }, [lang, i18n]);


    useEffect(() => {
        if (!isLoading && data) {
            setReservationData(data);
            console.log("RESERVATION FOR EDT", data);
        }
    }, [data, isLoading]);

    if (isLoading) {
        return <p>Loading...</p>;
    }

    if (!isLoading && !reservationData) {
        return <p>Error: Unable to fetch reservation data</p>;
    }

    return (
        <>
            {/* {console.log(data)} */}
            {reservationData && (
                <CreateBookingComponent openForEdit={true} reservationForEdit={data}></CreateBookingComponent>
            )}
        </>
    );
};
export default EditBookingComponent