import { useEffect, useState } from 'react';
import { useLazyGetUsersPerRoleQuery } from '../../features/users/UsersApiSlice';
import { Autocomplete, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function DriverAutoComplete(props) {
  const { drivers, onDriverChange, assignedDrivers } = props;
  const { t } = useTranslation();
  const [selectedDriver, setSelectedDriver] = useState(null);

  const handleChangeDriver = (newValue) => {
    onDriverChange(newValue);
    setSelectedDriver(newValue);
  };
  return (
    <>
      {drivers && (
        <Autocomplete
          disabled={assignedDrivers.length === 2}
          disablePortal={false}
          id="driver-autocomplete"
          fullWidth
          options={drivers || []}
          value={selectedDriver}
          getOptionLabel={(option) =>
            option.name != ' ' ? option.name : option.userName
          }
          getOptionKey={(option) => option.id}
          sx={{ padding: '0px' }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={t('rides.chooseDriver')}
            />
          )}
          onChange={(event, newValue) => handleChangeDriver(newValue)}
        ></Autocomplete>
      )}
    </>
  );
}
