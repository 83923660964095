import * as React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { useLazyGetPassengersByNameAnNumberQuery } from '../../../features/passangers/passangerApiSlice';
import { TextField } from '@mui/material';
import "./CreateBookingComponent.css"
import { textFieldStyles } from '../../../customStyles/customStyles';

function debounce(func, wait) {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func.apply(this, args);
    }, wait);
  };
}

export default function PassengersAutocomplete(props) {
  const {
    name,
    sx,
    placeholder,
    label,
    onPredictionSelect,
    value,
    handleInputChange,
    error,
    helperText,
    disabled,
    noOptionsText,
    onEnterClcik,
    reset
  } = props;
  const [getPredictions, { data }] = useLazyGetPassengersByNameAnNumberQuery();
  const [inputValue, setInputValue] = React.useState('');
  const [predictions, setPredictions] = React.useState('');


  const debouncedGetPredictions = React.useCallback(
    debounce((newInputValue) => {
      getPredictions(newInputValue);
    }, 500),
    []
  );

  const onInputChange = (event, newValue, reason) => {
    if(reason==="input"){
    console.log(newValue);
    // setInputValue(newValue);
    if (newValue) {
      debouncedGetPredictions(newValue);
    }
    handleInputChange(newValue)
  }
  };

  const onAutoChange = (event, newValue, reason) => {
    console.log(reason);
    if (newValue && newValue.name) {
      // setInputValue(newValue.name);
      // console.log("event")
      onPredictionSelect(newValue);
      setPredictions([])
      console.log(data)
    }
  };
  React.useEffect(()=>{
    console.log(data)
    setPredictions(data)
  },[data])
  // React.useEffect(()=>{
  //   setInputValue("");
  // },[reset])

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      console.log(predictions)
      if(predictions?.length>0){
        setPredictions([])
      }
      else{
        onEnterClcik(event)
      }
    }
  };

  return (
    <div className='passengers-autocomplete-main-conteiner' style={{width:"100%",position:"relative"}} >
      <label className="custom-label">{label}</label>

      <Autocomplete
      freeSolo
      disableClearable
        onChange={onAutoChange}
        onInputChange={onInputChange}
        inputValue={value}
        disabled={disabled}
        id="custom-input-demo"
        options={predictions || []}
        getOptionLabel={(option) => option.name + ' ' + option.phoneNumber}
        getOptionKey={(option) => option.id}
        sx={{padding:"0px"}}
        noOptionsText={noOptionsText}
        renderInput={(params) => (
          // <div style={{width:"100%"}} ref={params.InputProps.ref}>
      
              <TextField
              // {...params.inputProps}

                  // value={value}
                  placeholder={placeholder}
                  // onChange={onChangee
                  // }
                  {...params}
                  fullWidth
                  // sx={textFieldStyles}
                  error={error}
                  helperText={helperText}
                  disabled={disabled}
                  InputProps={{
                    ...params.InputProps,
                    type: 'search',
                    onKeyDown:handleKeyDown
                  }}

                ></TextField>
          // </div>
        )}
      />
    </div>
  );
}
