import { apiSlice } from '../../app/api/apiSlice';

export const stopSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // sendSMS: builder.mutation({
    //   query: (body) => ({
    //     url: `SMS/NotifyUsers`,
    //     method: 'POST',
    //     body,
    //   }),
    // }),
    getRestrictedDatesForRoute: builder.query({
        query: (routeId) => ({
            url: `RestrictDates/GetRestrictedDatesForRoute?routeId=${routeId}`,
            method: "GET",
          }),
      }),
      editRestrictedDate: builder.mutation({
        query: ({routeId,dateOnly,direction}) => ({
            url: `RestrictDates/EditRestrictedDates?routeId=${routeId}&dateonly=${dateOnly}&direction=${direction}`,
            method: "PUT",
          }),
      invalidatesTags: ['directions'],

      }),


    
  }),
});

export const {
    useLazyGetRestrictedDatesForRouteQuery,
    useEditRestrictedDateMutation
} = stopSlice;
