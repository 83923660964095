import { apiSlice } from "../../app/api/apiSlice";
import { getDirections } from "../../services/DirectionService";

const handleResponseError = (response) => {
  if (!response.success) {
    console.log(response);
    throw new Error(response.error.message || "Unknown error");
  }
  return response;
};
const handleOnError = (error) => {
  console.log("Error fetching route:", error);
};

export const routesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRouteById: builder.query({
      query: (id) => `Route/GetRouteById?routeId=${id}`,
    }),

    getFillteredRoutesPerPage: builder.query({
      query: (pars) => ({
        url: "Route/GetRoutesPerPage",
        method: "GET",
        params: { ...pars },
      }),
    }),

    updateRoute: builder.mutation({
      query: (body) => ({
        url: "Route/UpdateRoute",
        method: "PUT",
        body,
      }),
    }),
    disableRoute: builder.mutation({
      query: (id) => ({
        url: `Route/DisableRoute?id=${id}`,
        method: "PUT",
      }),
    }),
    enableRoute: builder.mutation({
      query: (id) => ({
        url: `Route/EnableRoute?id=${id}`,
        method: "PUT",
      }),
    }),
    addRoute: builder.mutation({
      query: (body) => ({
        url: `Route/AddRoute`,
        method: "POST",
        body,
      }),
    }),
    addRouteWithStops: builder.mutation({
      query: (body) => ({
        url: `Route/AddRouteWithRouteStops`,
        method: "POST",
        body,
      }),
    }),
    checkExistingRouteTitle: builder.query({
      query: (title) => ({
        url: `Route/CheckExistingRouteTitle?title=${title}`,
        method: "GET",
      }),
    }),
    getRoutesByTitle: builder.query({
      query: (title) => ({
        url: `Route/GetRoutesByTitle?title=${title}`,
        method: "GET",
      }),
    }),
    getDirections: builder.query({
      query: (id) => ({
        url: `Route/GetDirections?routeid=${id}`,
        method: "GET",
      }),
    }),

    getRoutesAndDirections: builder.query({
      query: () => ({
        url: `Route/GetRoutesAndDirections`,
        method: "GET",
      }),
    }),

    getDirectionsAndRestrictedDates: builder.query({
      query: (id) => ({
        url: `Route/GetDirectionsAndRestrictedDates?routeid=${id}`,
        method: "GET",
      }),
      providesTags:["directions"]
    }),
    
    getRoutesForDropDown: builder.query({
      query: () => ({
        url: `Route/GetRoutesForDropDown`,
        method: "GET",
      }),
    }),
    getOnlyTitles: builder.query({
      query: () => ({
        url: `Route/GetOnlyTitles`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetRouteByIdQuery,
  useGetFillteredRoutesPerPageQuery,
  useLazyGetFillteredRoutesPerPageQuery,
  useUpdateRouteMutation,
  useDisableRouteMutation,
  useEnableRouteMutation,
  useAddRouteMutation,
  useAddRouteWithStopsMutation,
  useLazyCheckExistingRouteTitleQuery,
  useLazyGetRoutesByTitleQuery,
  useLazyGetDirectionsQuery,
  useLazyGetRoutesForDropDownQuery,
  useLazyGetOnlyTitlesQuery,
  useGetOnlyTitlesQuery,
  useLazyGetDirectionsAndRestrictedDatesQuery,
  useLazyGetRoutesAndDirectionsQuery
} = routesApiSlice;
