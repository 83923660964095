import React, { useEffect, useState } from 'react';

import '../Route/AddRouteComponent/AddRouteByAutocomplete.css';
import { useTranslation } from 'react-i18next';
import { roles } from '../../config';
import { Select, InputLabel, FormControl, MenuItem } from '@mui/material';
import { formControlStyles } from '../../customStyles/customStyles';

const DirectionFormControll = (props) => {
  const { t } = useTranslation();

  const { onChange, value, directions } = props;

  return (
    <>
      <FormControl fullWidth>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          name="direction"
          value={value}
          onChange={onChange}
          sx={{ padding: '0px !important' }}
        >
          {directions.map((directionn, index) => {
            return (
              <MenuItem key={index} value={directionn.direction}>
                {directionn.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </>
  );
};

export default DirectionFormControll;
