import { useState, useEffect } from 'react';
import { useLazyGetDirectionsAndRestrictedDatesQuery } from '../features/routes/routesSlice';
import { useLazyGetPredefinedTimesQuery } from '../features/predefinedTime/predefinedTimeSlice';

const useRouteData = (routeId, direction) => {
  const [directions, setDirections] = useState([]);
  const [restrictedDates, setRestrictedDates] = useState([]);
  const [predefinedTimes, setPredefinedTimes] = useState([]);
  const [selectedDirection, setSelectedDirection] = useState();
  const [selectedPredefinedTimes, setSelectedPredefinedTimes] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [getRouteDatas, { data }] =
    useLazyGetDirectionsAndRestrictedDatesQuery();
  const [getPredefinedTimes, { times }] = useLazyGetPredefinedTimesQuery();

  useEffect(() => {
    if (routeId) {
      setLoading(true);
      getRouteDatas(routeId)
        .then((res) => {
          console.log(res);
          const directionsData = res.data.directions;
          setDirections([
            {
              direction: directionsData.departure.direction,
              name: directionsData.departure.name,
            },
            {
              direction: directionsData.return.direction,
              name: directionsData.return.name,
            },
          ]);

          //   setRestrictedDates(res.data.restrictedDates); // Store restricted dates
          setPredefinedTimes(res.data.predefinedTimes); // Store predefined times
        })
        .catch((error) => {
          console.error('Error fetching restricted dates:', error);
          setError(error); // Set error state
        })
        .finally(() => {
          setLoading(false); // Set loading to false when finished
        });
    }
  }, [routeId]);

  const checkAndSetSelectedPredefinedTimes=(predefinedTimes)=>{
    if (selectedDirection === 'departure') {
        setSelectedPredefinedTimes(predefinedTimes.predefinedDeparture);
      } else {
        setSelectedPredefinedTimes(predefinedTimes.predefinedReturn);
      }
  }

  useEffect(() => {
    if (directions.length > 0) {
      setSelectedDirection(directions[0].direction);
    }
  }, [directions]);
  useEffect(() => {
    if (predefinedTimes && selectedDirection) {
        checkAndSetSelectedPredefinedTimes(predefinedTimes)
    }
  }, [selectedDirection]);
  useEffect(() => {
    console.log(selectedPredefinedTimes);
  }, [selectedPredefinedTimes]);

  const refachRestrictedDates = () => {
    getPredefinedTimes(routeId)
      .then((res) => {
        console.log(res);
        setPredefinedTimes(res.data);
        checkAndSetSelectedPredefinedTimes(res.data)

      })
      .catch((error) => {
        console.error('Error fetching restricted dates:', error); // Handle any errors
      });
  };

  return {
    directions,
    restrictedDates,
    predefinedTimes,
    loading,
    error,
    setSelectedDirection,
    selectedDirection,
    selectedPredefinedTimes,
    refachRestrictedDates
  };
};

export default useRouteData;
