import { apiSlice } from "../../app/api/apiSlice";
import { BASE_API_URL } from "../../config";

export const authApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        login: builder.mutation({
            query: credentials => ({
                url: BASE_API_URL + 'Authentification/Login',
                method: 'POST',
                body: { ...credentials }
            })
        }),
        changePassword: builder.mutation({
            query: passwordDetails => ({
                url: BASE_API_URL + 'Authentification/ChangePassword',
                method: 'PUT',
                body: passwordDetails 
            })
        }),
        resetPassword: builder.mutation({
            query: passwordDetails => ({
                url: BASE_API_URL + 'Authentification/ResetPassword',
                method: 'PUT',
                body: passwordDetails
            })
        }),
        register: builder.mutation({
            query: registrationBody=> ({
                url: BASE_API_URL + 'Authentification/Register',
                method: 'POST',
                body: registrationBody 
            })
        }),
        addUser: builder.mutation({
            query: registrationBody=> ({
                url: BASE_API_URL + 'Authentification/AddUser',
                method: 'POST',
                body: registrationBody 
            })
        }),
        registerInvitation: builder.mutation({
            query: registrationBody=> ({
                url: BASE_API_URL + 'Authentification/RegisterWhitRegistrationLink',
                method: 'POST',
                body: registrationBody 
            })
        }),

        inviteUser: builder.mutation({
            query: body=> ({
                url: BASE_API_URL + `Authentification/SendRegistrationLink`,
                method: 'POST',
                body:body
            })
        }),
        forgetPassword: builder.mutation({
            query: email=> ({
                url: BASE_API_URL + `Authentification/Forget-Password?emailCheck=${email}`,
                method: 'POST',
            })
        }),
        getInvitationToken: builder.query({
            query: token=> ({
                url: BASE_API_URL + `Authentification/GetInvitationToken?token=${token}`,
                method: 'GET',
            })
        }),
        confirmEmail: builder.mutation({
            query: token=> ({
                url: BASE_API_URL + `Authentification/ConfirmEmail?emailToken=${token}`,
                method: 'GET',
            })
        }),
        
        // getFillteredRoutesPerPage: builder.query({
        //     query: (pars) => ({
        //       url: "Route/GetRoutesPerPage",
        //       method: "GET",
        //       params: { ...pars },
        //     }),
        //   }),
    })
    
})

export const {
    useLoginMutation,useChangePasswordMutation,useResetPasswordMutation,useRegisterMutation,useAddUserMutation,useForgetPasswordMutation,useGetInvitationTokenQuery,useRegisterInvitationMutation,useInviteUserMutation,
    //useConfirmEmailQuery
    useConfirmEmailMutation

} = authApiSlice