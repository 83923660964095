import * as React from 'react';
import { useState } from 'react';
import './PaymentMethodDeleteForm.css';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';
import { useDisablePaymentMethodMutation } from '../../../features/paymentmethod/PaymentMethodSlice';

export default function DeletePaymentMethodForm({onSuccess, getPaymentMethodAfterDelete, paymentMethodId}) {

  const { t, i18n } = useTranslation();
  const [disablePaymentMethod, { isLoading, isError, isSuccess,error }] = useDisablePaymentMethodMutation();
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await disablePaymentMethod(paymentMethodId).unwrap();
      if (onSuccess) {
        onSuccess();
      }
      if (getPaymentMethodAfterDelete) {
        getPaymentMethodAfterDelete(true);
      }
    } catch (err) {
      console.error('Failed to create subagent: ', err);
      getPaymentMethodAfterDelete(false);
    }
  };

  const closeDialog = () => {
    onSuccess();
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Typography color="black" id="textDelete">
        {t('paymentMethod.acceptDelete')}
      </Typography>

      <Box className="dialog-delete">
        <Button type="submit" variant="outlined" color="primary">
          {t('paymentMethod.yes')}
        </Button>
        <Button
          type="button"
          variant="outlined"
          color="primary"
          onClick={closeDialog}
        >
          {t('paymentMethod.no')}
        </Button>
      </Box>
    </Box>
  );
}
