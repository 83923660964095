import React, { useEffect, useState } from 'react';
import {
  TextField,
  Card,
  Button,
  Checkbox,
  FormControlLabel
} from "@mui/material";
import { useEditBookingMutation } from '../../../features/bookings/bookingsApiSlice';
import '../AddBooking/AddBookingForm.css';
import { useSnackbar } from '../../SneckBarProvider';
import { useTranslation } from 'react-i18next';
import { DatePicker, DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

function UpdateBookingForm(props) {
  const { t, i18n } = useTranslation();

  const formatDate = (date, time) => {
    const [month, day, year] = date.split('/');
    const [hour, minute] = time.split(':');
    const ampm = minute.includes('PM') ? 'PM' : 'AM';
    const formattedHour = hour.padStart(2, '0');
    const formattedTime = `${formattedHour}:${minute.slice(0, 2)}`;
    const formattedDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    return `${formattedDate}T${formattedTime}`;
  };

  //const showSnackbar = useSnackbar();
  const showSnackbar = useSnackbar();

  const [bookingData, setBookingData] = useState({
    id: props.booking.id,
    bookingCode: props.booking.bookingCode,
    departure: props.booking.date,
    capacity: props.booking.capacity,
    departure: formatDate(props.booking.date, props.booking.time),
    //reservationsCount: props.booking.reservationsCount,
    isRoundTrip: props.booking.isRoundTrip,
    isAvailable: props.booking.isAvailable,
  });

  const [editBooking] = useEditBookingMutation();

  const handleUpdateBookingClick = async (event) => {
    event.preventDefault();

    try {
      const response = await editBooking(bookingData).unwrap();
      showSnackbar(response?.message, "success");
    } catch (error) {
      console.log(error)
      showSnackbar(error?.data?.Message, "error");
    }

  }

  useEffect(() => {
    console.log(bookingData)
  }, [bookingData])

  const handleDateChange = (newVal, key) => {
    setBookingData((prevVal) => ({
      ...prevVal,
      [key]: newVal ? dayjs(newVal).format('YYYY-MM-DDTHH:mm') : null,
    }));
  };
  return (
    <div className="add-form">
      <Card className='add-booking' variant='outlined' sx={{ border: "none", boxShadow: "none" }}>
        <form style={{ margin: '20px' }} onSubmit={(event) => handleUpdateBookingClick(event)} >

          <div className="add-booking-booking-item">
            <TextField
              className="add-booking-form-field"
              label={(t('customDialog.bookingCode'))}
              variant='outlined'
              value={bookingData.bookingCode}
              onChange={(event) => {
                setBookingData({
                  ...bookingData,
                  bookingCode: event.target.value
                })
              }}
            ></TextField>
          </div>
          <div className="add-booking-booking-item">
            {/* <TextField
              className="add-booking-form-field"
              label={(t('customDialog.departure'))}
              type='datetime-local'
              value={bookingData.departure}
              onChange={(event) => {
                setBookingData({
                  ...bookingData,
                  departure: event.target.value
                })
              }}></TextField> */}

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                label={(t('customDialog.departure'))}
                className="add-booking-form-field"
                value={bookingData.departure ? dayjs(bookingData.departure, 'YYYY-MM-DDTHH:mm') : null}
                onChange={(newVal) => handleDateChange(newVal, 'departure')}
                renderInput={(params) => <TextField {...params} />}
                format="DD/MM/YYYY hh:mm"
              />
            </LocalizationProvider>
          </div>
          <div className="add-booking-booking-item">
            <TextField
              className="add-booking-form-field"
              type='number'
              label={(t('customDialog.capacity'))}
              value={bookingData.capacity}
              onChange={(event) => {
                const newVal = parseInt(event.target.value)
                setBookingData({
                  ...bookingData,
                  capacity: newVal
                })
              }}></TextField>
          </div>
          <div className="add-booking-booking-item">
            <FormControlLabel
              className="add-booking-form-field"
              control={
                <Checkbox label={(t('customDialog.roundTrip'))} checked={bookingData.isRoundTrip}
                  onChange={(event) => {
                    setBookingData({
                      ...bookingData,
                      isRoundTrip: event.target.checked
                    })
                  }}></Checkbox>
              }
              label={(t('customDialog.roundTrip'))} />
          </div>
          <div className="add-booking-booking-item">
            <FormControlLabel
              className="add-booking-form-field"
              control={
                <Checkbox checked={bookingData.isAvailable}
                  onChange={(event) => {
                    setBookingData({
                      ...bookingData,
                      isAvailable: event.target.checked
                    })
                  }}></Checkbox>
              }
              label={(t('customDialog.isAvailable'))} />


          </div>

          <div className="add-booking-booking-button">
            <Button type='submit' variant='contained' color="secondary">
              {t('customDialog.editRide')}
            </Button>
          </div>

        </form>
      </Card>
    </div>
  )
}

export default UpdateBookingForm;