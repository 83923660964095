import React, { createContext, useState, useContext, useCallback } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useTranslation } from "react-i18next";
import { IconButton, Slide } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';


const SnackbarContext = createContext();

export const SnackbarProvider = ({ children }) => {
  const { t } = useTranslation();

  function SlideTransitionLeft(props) {
    return <Slide {...props} direction="left" />;
  }
  function SlideExitTransition(props) {
    return <Slide {...props} direction="right" />;
  }
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: '',
    severity: 'info'
  });

  const showSnackbar = useCallback((message, severity = 'info') => {
    var translatedMessage;
    console.log(message)
    translatedMessage=t(`responses.${message}`);
    console.log(translatedMessage)
    if (translatedMessage === `responses.${message}` || !translatedMessage) {
      translatedMessage = message;
    }

    setSnackbarState({ open: true, message:translatedMessage, severity });
  }, []);

  const handleClose = useCallback(() => {
    setSnackbarState((prevState) => ({ ...prevState, open: false }));
  }, []);

  return (
    <SnackbarContext.Provider value={showSnackbar}>
      {children}
      <Snackbar 
        open={snackbarState.open}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={snackbarState.severity}
          variant="filled"

          action={
            <IconButton
              size="small"
              sx={{
                color: 'white', 
              }}
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        >
          {snackbarState.message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => {
  return useContext(SnackbarContext);
};