import { useState } from 'react';
import './PredefinedTime.css';
import dayjs from 'dayjs';

export default function PredefinedTimesMenuItem({ time, value,onClick }) {
  //   const [open,setOpen]=useState(false);
  console.log(value);
  let className = 'predefined-times-menu-item-main-container';
  if (time.time === value) {
    className = 'predefined-times-menu-item-main-container-selected';
  }

  const handlePredefinedTimeClcik=()=>{
    if(time.time!==value){
        onClick(dayjs(time.time,"HH:mm"))
    }
  }

  return (
    <>
      <div onClick={handlePredefinedTimeClcik} className={className}>{time.time}</div>
      {/* <div  className='predefined-times-menu-item-main-container'>
            18:30
        </div>
        <div  className='predefined-times-menu-item-main-container'>
            18:30
        </div>
        <div  className='predefined-times-menu-item-main-container'>
            18:30
        </div> */}
    </>
  );
}
