import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  filters: {
    date: null,
    route: undefined,
    direction: "",
    pageNumber:1,
    pageSize:10
  }
};

export const ridesFiltersSlice = createSlice({
  name: 'ridesFilters',
  initialState,
  reducers: {
    setFilters: (state, action) => {
      state.filters = { ...state.filters, ...action.payload };
    },
    resetFilters: (state) => {
      state.filters = initialState.filters;
    },
  },
});

// Export actions
export const { setFilters, resetFilters } = ridesFiltersSlice.actions;

// Export reducer
export default ridesFiltersSlice.reducer;
