import React from 'react';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { Transition } from '../../animations/transition';
import './CustomDialog.css';
import zIndex from '@mui/material/styles/zIndex';
import { useTranslation } from 'react-i18next';

const CustomDialog = ({
  onOpen,
  onClose,
  contentTitle,
  content,
  children,
  customMargin,
  className,
  customStyle,
  paperPropsStyle,
  disableBackdropClick,
  hasScroll,
}) => {
  if (customMargin === undefined) {
    customMargin = '20px';
  }
  const { t } = useTranslation();
  let translatedTitle;
  if (contentTitle) {
    translatedTitle = t(`customDialog.${contentTitle}`);

    if (
      !translatedTitle ||
      translatedTitle === `customDialog.${contentTitle}`
    ) {
      translatedTitle = contentTitle;
    }
  }
  // if (customZindex === undefined) {
  //   customMargin = "20px";
  // }
  return (
    <Dialog
      className="custom-dialog-main-container"
      sx={customStyle}
      // style={{ zIndex: "100", overflow:"visible" }}
      style={{ zIndex: '100' }}
      open={onOpen}
      //onClose={onClose}
      /*onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onClose(event, reason);
        }
      }}*/
      PaperProps={{
        sx: paperPropsStyle,
      }}
      onClose={(event, reason) => {
        if (disableBackdropClick) {
          if (reason !== 'backdropClick') {
            onClose(event, reason);
          }
        } else {
          onClose(event, reason);
        }
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      TransitionComponent={Transition}
    >
      {contentTitle !== undefined && (
        <DialogTitle
          id="alert-dialog-title"
          className={`${
            className !== undefined ? className : 'custom-dialog-title'
          }`}
        >
          {translatedTitle}
        </DialogTitle>
      )}
      {content !== undefined && (
        <DialogContent sx={{ color: '#83111D' }}>
          {/* <DialogContentText
            id="alert-dialog-description"
            sx={{ color: "#83111D" }}
          > */}
          {content}
          {/* </DialogContentText> */}
        </DialogContent>
      )}

      <div
        style={{
          overflowY: hasScroll == 'nema' ? '' : 'auto',
          padding: customMargin,
        }}
        className="custom-dialog-content-container"
      >
        {children}
      </div>
      {/* <DialogActions>
        <Button onClick={onClose}>CANCEL</Button>
      </DialogActions> */}
    </Dialog>
  );
};

export default CustomDialog;
