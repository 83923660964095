import { useState } from 'react';
import ComfirmationDialog from '../Dialog/ConfirmationDialog';
import './PredefinedTime.css';
import ClearIcon from '@mui/icons-material/Clear';
import { useTranslation } from 'react-i18next';

export default function PredefinedTimeBadge({ time, onRemovePredefinedTime }) {
  const [open, setOpen] = useState(false);
  let className = 'predefined-time-badge-container';
  if (time.time === time.id) {
    className = 'unsaved-predefined-time-badge-container';
  }

  const handleOpenDialog = () => {
    setOpen(true);
  };
  const handleRemovePredefinedTime = () => {
    onRemovePredefinedTime(time);
    handleCloseDialog();
  };
  const handleCloseDialog = () => {
    setOpen(false);
  };
  return (
    <>
      <div className={className}>
        <span>{time.time}</span>
        <ClearIcon
          fontSize="16px"
          style={{ cursor: 'pointer' }}
          onClick={handleOpenDialog}
        />
      </div>
      {open && (
        <ComfirmationDialog
          open={open}
          onClose={handleCloseDialog}
          dialogContent={"removePredefinedTime"}
          onNo={handleCloseDialog}
          onYes={handleRemovePredefinedTime}
        />
      )}
    </>
  );
}
