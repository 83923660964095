import * as React from 'react';
import { useState } from 'react';
import './SubagentDeleteForm.css';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';
import { useDisableSubagentMutation } from '../../../features/subagents/SubagentSlice';

export default function DeleteSubagentForm({ onSuccess, getSubagentsAfterDelete, subagentId}) {

  const { t, i18n } = useTranslation();
  const [disableSubagent, { isLoading, isError, isSuccess }] = useDisableSubagentMutation();
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await disableSubagent(subagentId).unwrap();
      if (onSuccess) {
        onSuccess();
      }
      if (getSubagentsAfterDelete) {
        getSubagentsAfterDelete(true);
      }
    } catch (err) {
      console.error('Failed to create subagent: ', err);
      getSubagentsAfterDelete(false);
    }
  };

  const closeDialog = () => {
    onSuccess();
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Typography color="black" id="textDelete">
        {t('subagent.dialogDeleteSubagent')}
      </Typography>

      <Box className="dialog-delete">
        <Button type="submit" variant="outlined">
          {t('subagent.yes')}
        </Button>
        <Button type="button" variant="outlined" onClick={closeDialog}>
          {t('subagent.no')}
        </Button>
      </Box>
    </Box>
  );
}
